import react, { useState, useEffect, useContext } from 'react'
import './ExerciseDescription.css'
import Drawing from '../CoachPaint/CoachPaintComponents/Drawing'
import { ExerciseDensityElements, InternalLogicElements, NewExerciseElements } from '../../webAppComponents/CreateTrainingItems/CreateTrainingItems';
import { LuClock } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import DoghnutGraph from '../DoughnutGraph/DoughnutGraph.jsx';


const ExerciseDescription = ({ exercise, trainingOptions, editMode, handleExerciseDataChange }) => {
    const { t } = useTranslation(['create-training-page']);

    const toStringFormat = (time) => {
      if (typeof time === "string")
        return time

      // from seconds to hh:mm:ss
      let hours = Math.floor(time / 3600);
      if (hours / 10 < 1)
        hours = `0${hours}`

      let minutes = Math.floor((time - (hours * 3600)) / 60);
      if (minutes / 10 < 1)
        minutes = `0${minutes}`

      let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
      if (seconds / 10 < 1)
        seconds = `0${seconds}`

      return (`${hours}:${minutes}:${seconds}`);
    }

    const fromStringToSeconds = (time_string) => {
      const [hours, minutes, seconds] = time_string.split(':').map(Number);
      const time_seconds = hours * 3600 + minutes * 60 + seconds;

      return time_seconds
    }

    const totalTimeSeconds = () => {
      if (!exercise?.rep_duration || !exercise?.rest_time) return 0;
      return  ( exercise?.num_reps * fromStringToSeconds(exercise?.rep_duration) + (exercise?.num_reps * fromStringToSeconds(exercise?.rest_time) ) );
    }

    const calculateTimeDensity = () => {
      if (!exercise?.rep_duration) return 0;
      if (exercise?.rep_duration && !exercise?.rest_time) return 100;

      const rep_seconds = fromStringToSeconds(exercise?.rep_duration);
      const rest_seconds = fromStringToSeconds(exercise?.rest_time)

      return Math.round(rep_seconds / (rest_seconds + rep_seconds) * 100 );
    }

    const [timeDensity, setTimeDensity] = useState(calculateTimeDensity())

    useEffect(() => {
      setTimeDensity(calculateTimeDensity());
    }, [exercise?.rep_duration, exercise?.rest_time]);

    const mapValueToString = (value, choicesArray) => {
        // Ensure choicesArray is an array
        // Find the matching choice
        if (!Array.isArray(choicesArray)) {
            return '';
        }

        const matchingChoice = choicesArray.find((num) => {
          if(num.value !== undefined && num.value === value)
            return num.name

          if(num[0] == value )
            return num[1];
        });

        // Handle case where matchingChoice is undefined
        if (!matchingChoice) {
            console.error("Matching choice not found");
            return '';
        }

        // Return the string representation (index 1 of the found choice)
        return matchingChoice[1] ? matchingChoice[1] : matchingChoice.name;
    };

    const uniqueStageId = `stage-id-${exercise?.drawing?.id}`;

    const handleTimeInputChange = (rest_time, event) => {
      event.preventDefault();

      const input = event.target.value;
      const split_input = input.split(":")

      if (split_input.some((element) => isNaN(element))) return
      if (split_input.length != 3) return;
      if(split_input.some((splited) => splited.length > 2)) return

      if (rest_time) {
        handleExerciseDataChange('rest_time', input)
      } else {
        handleExerciseDataChange('rep_duration',input )
      }

      return;
    };

    return (
        <div className="webapp-exercise-description ">
            <div className="webapp-exercise-description-title">
                <h2>{exercise?.name}</h2>
            </div>
            {exercise && trainingOptions ? (
                <div className="webapp-training-exercise-description-container">

                    <div className="webapp-exercise-left-info-container">
                        <div className="webapp-exercise-info-containers">
                            <div className="webapp-exercise-description-titles">
                                <span>1.</span>
                                <h3>{t('Elements of the phase or moment')} {t('of the game to be developed in the exercise')}</h3>
                            </div>
                            {editMode ?
                              <div className="webapp-exercise-info">
                               <NewExerciseElements currentExercise={exercise} dropdownItems={trainingOptions} handleExerciseDataChange={handleExerciseDataChange} t={t}/>
                              </div>
                              :
                              <div className="webapp-exercise-info">
                                  <ExerciseInfo label={t('General Bases Of Orientation')} value={exercise?.general_bases_of_orientation} type="list" />
                                  <ExerciseInfo label={t('Game Actions')} value={exercise?.game_actions} type="list" />
                                  <ExerciseInfo label={t('Principles Of Play')} value={exercise?.principles_of_play} type="list" />
                              </div>
                            }
                        </div>
                        <div className="webapp-exercise-info-containers">
                            <div className="webapp-exercise-description-titles">
                                <span>2.</span>
                                <h3>{t('Elements of the density of the exercise')}</h3>
                            </div>
                            {editMode ?
                                <ExerciseDensityElements currentExercise={exercise} timeDensity={timeDensity} vertical={true} handleExerciseDataChange={handleExerciseDataChange} handleTimeInputChange={handleTimeInputChange} t={t}/>
                              :
                              <div className="webapp-exercise-info">
                                  <ExerciseInfo label={t('Nº of repetitions')} value={exercise?.num_reps} />
                                  <ExerciseInfo label={t('Repetition duration')} value={exercise?.rep_duration} />
                                  <ExerciseInfo label={t('Pause duration')} value={exercise?.rest_time} />
                                  <ExerciseInfo label={t('Total Duration')} value={toStringFormat(totalTimeSeconds())} />
                                  <ExerciseInfo label={`${t('Density or %')} ${t('of effective')} ${t('work time')}`} value={`${timeDensity} %`} />
                              </div>
                            }
                        </div>
                        <div className="webapp-exercise-info-containers">
                            <div className="webapp-exercise-description-titles">
                                <span>3.</span>
                                <h3>{t('Elements of internal logic of the game')}</h3>
                            </div>
                            {editMode ?
                              null
                              :
                              <div className="webapp-exercise-info">
                                 <ExerciseInfo label={t('Ball or mobile')} value={mapValueToString(exercise?.ball_or_mobile, trainingOptions.ball_or_mobile_choices)} />
                                 <ExerciseInfo label={('Action space')} value={mapValueToString(exercise?.space_of_action, trainingOptions.space_of_action_choices)} />
                                 <ExerciseInfo label={t('Organization of teams in the exercise')} value={mapValueToString(exercise?.organization_of_teams_in_the_exercise, trainingOptions.organization_of_teams_in_the_exercise_choices)} />
                                 <ExerciseInfo label={t('Number of players')} value={mapValueToString(exercise?.number_of_players_per_team, trainingOptions.number_of_players_per_team_choices)} />
                                 <ExerciseInfo label={t('Opposing players')} value={mapValueToString(exercise?.players_in_opposition, trainingOptions.players_in_opposition_choices)} />
                                 <ExerciseInfo label={t('m2 per player inside the field')} value={mapValueToString(exercise?.m2_for_player_in_the_field, trainingOptions.m2_for_player_in_the_field_choices)} />
                                 <ExerciseInfo label={t('Tactical orientation')} value={mapValueToString(exercise?.tatical_orientation, trainingOptions.tatical_orientation_choices)} />
                                 <ExerciseInfo label={t('Practicing goalkeepers')} value={mapValueToString(exercise?.goalkeepers_in_exercise, trainingOptions.goalkeepers_in_exercise_choices)} />
                                 <ExerciseInfo label={t('Decision making')} value={mapValueToString(exercise?.decision_making, trainingOptions.decision_making_choices)} />
                              </div>
                            }
                        </div>
                    </div>
                    <div className="webapp-exercise-right-info-container">
                        <div className="webapp-exercise-duration-info">
                            <h5>{t('Total Duration')} </h5>
                            <div className="session-training-total-duration"> <span className="black-16px-600"><LuClock /> {exercise?.total_duration} {t('min')}</span></div>
                        </div>
                        <div className="webapp-exercise-info-drawing-container" >
                            <div className="webapp-exercise-description-page-drawing" id={`${uniqueStageId}-display`}>
                                {exercise.drawing.lines &&
                                    <Drawing justDisplay={true} lines={exercise?.drawing?.lines} campoState={exercise.drawing?.image?.image} stamps={exercise?.drawing?.stamps} stageId={`${uniqueStageId}-display`} />
                                }
                            </div>
                            <div className='webapp-exercise-descriprions-graphs-container'>
                                {exercise && <DoghnutGraph title={`${t('Density or %')} ${t('of effective')} ${t('work time')}`} labels={[t("active"), t("rest")]} data={[timeDensity, 100 - timeDensity]} centerText={'%'} darkMode={true}/>}
                                {exercise && <DoghnutGraph title={`IE (0-10)`} data={[exercise.IET_CR10,( 10 - exercise.IET_CR10)]} centerText={'/10'} darkMode={true} />}
                            </div>
                        </div>
                    </div>

                </div>
            ) : null}
            {editMode &&
              <div className="webapp-exercise-info">
                <InternalLogicElements currentExercise={exercise} dropdownItems={trainingOptions} handleExerciseDataChange={handleExerciseDataChange} t={t}/>
              </div>
            }

        </div>
    )
}




const ExerciseInfo = (props) => {
    const { t } = useTranslation(['create-training-page']);

    return (
        <div className="exercise-description-info">
            <span></span>
            <div>
                <h4>{t(props?.label)}</h4>
                {props.type === "list" && Array.isArray(props.value) === false ? (
                    <p className="webapp-exercise-description-item">{t(props.value.name)}</p>
                ) : props.type === "list" && Array.isArray(props.value) === true ? (
                    <ul>
                        {props.value.map((item, index) => (
                            // Check if is the last item to remove the comma
                            index === props.value.length - 1 ?
                                <il className="webapp-exercise-description-item" key={index}>{t(item.name)}</il>
                                :
                                <il className="webapp-exercise-description-item" key={index}>{t(item.name)}, </il>
                        ))}
                    </ul>
                ) :
                    (
                        <p className='webapp-exercise-description-item'>{props.value}</p>
                    )}
            </div>
        </div>
    );
};

export default ExerciseDescription
