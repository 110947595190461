import "./CardHistory.css";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import Modal from 'react-modal';
import Avatar from "react-avatar-edit";

import axios from "axios";
import AuthContext from "../../context/AuthContext";

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import ConfirmationPopup from "../../webAppComponents/ConfirmationPopup/Popup";
import CardInfo from "./CardInfo";
import TextPlaceHolder from "../../webAppComponents/PlaceHolders/PlaceHolders";
import PastPurchases from "../../webAppComponents/PastPurchases/PastPurchases";

import cardicon from "../../webAppAssets/card-icon.svg"
import cardiconplus from "../../webAppAssets/card-icon-plus.svg"
import cardiconx from "../../webAppAssets/card-icon-x.svg"
import ImagePlus from "../../webAppAssets/image-plus.svg"

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CardHistory = () => {
    const { t } = useTranslation(['card-history']);
    const [coachData, setCoachData] = useState([]);
    const [coachImage, setCoachImage] = useState(null);
    const [subscription, setSubscription] = useState([]);
    const [methods, setMethods] = useState([]);
    const [history, setHistory] = useState(null);
    const [reload, setReload] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [popup, setPopup] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [changeMode, setChangeMode] = useState(false);
    const [selected, setSelected] = useState(null);
    const [cardToDelete, setCardToDelete] = useState(null);

    const [card, setCard] = useState({});

    const { authTokens, logoutUser } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/payment-history', { headers })
            .then((response) => {
                // Handle the successful response here
                setHistory(response.data)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }, [])


    useEffect(() => {
        // Make a GET request with the specified headers
        axios.get(process.env.REACT_APP_BACKEND + 'users/subscription', { headers })
            .then((response) => {
                // Handle the successful response here
                setCoachImage(() => {
                    let fullImageUrl = null;

                    if (response.data.image_url !== null)
                        fullImageUrl = process.env.REACT_APP_BACKEND + response.data.image_url;

                    return fullImageUrl;
                });
                setCoachData(response.data.user)
                setSubscription(response.data.subscription)
                setSelected(response.data.subscription.default_payment_method)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });

        axios.get(process.env.REACT_APP_BACKEND + 'users/payments-methods', { headers })
            .then((response) => {
                // Handle the successful response here
                setMethods(response.data.data)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });

    }, [reload])

    const addCard = () => {
        axios.post(process.env.REACT_APP_BACKEND + 'users/add-payment-method', {}, { headers })
            .then((response) => {
                // Handle the successful response here
                window.location.href = response.data.url;
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }

    const deleteCard = async () => {
        await axios.delete(process.env.REACT_APP_BACKEND + 'users/delete-payment-method', {
            headers: headers, // Make sure to include the headers inside the object
            data: { "paymentMethodId": cardToDelete.id } // Sending data in the request body
        })
            .then((response) => {
                // Handle the successful response here
                setCardToDelete(null);
                setReload(!reload);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
        setDeleteMode(false);
    }
    
    const setDefaultCard = async () => {
        await axios.patch(process.env.REACT_APP_BACKEND + 'users/set-default-payment-method', { "paymentMethodId": selected.id }, { headers })
            .then((response) => {
                // Handle the successful response here
                setReload(!reload);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
                setSelected(subscription.default_payment_method)
            });
        setChangeMode(false);
    }

    const handleChangeMode = (origin) => {
        if (origin === "change") {
            if (!changeMode) {
                setChangeMode(true)
            }
        }
        if (origin === "cancel") {
            if (changeMode) {
                setChangeMode(false)
                setSelected(subscription.default_payment_method)
            }
        }
        if (origin === "save") {
            setDefaultCard()
            setChangeMode(false)
        }
    }

    const handlePopup = (e) => {
        setCard(e)
        setPopup(true);
    }

    async function handleImageUpload(file) {
        try {
            // Create FormData object and append the blob
            const formData = new FormData();
            formData.append("profile_image", file);

            // If file larger than 5MB, return
            if (file.size > 5242880) {
                toast.error(t('file-size-error'));
                return;
            }

            // Make a PATCH request to save the image to the backend
            const response = await axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-coach-image', formData, { headers });

            if (response.status === 200) {
                // Handle the successful response here
                setReload(prevReload => !prevReload);
                

                // Assuming setCoachImage is a state updater function
                setCoachImage(URL.createObjectURL(file));
                return;
            }
        } catch (error) {
            // Handle the error here
            console.error("Error uploading image:", error);
        }
    }

    async function onCrop(croppedImage) {
        try {
            setCoachImage(croppedImage);
            // Extract base64 data from data URI
            var base64Data = croppedImage.split(',')[1];
            // Convert base64 to binary
            var binaryData = atob(base64Data);
            // Create ArrayBuffer and Uint8Array
            var arrayBuffer = new ArrayBuffer(binaryData.length);
            var uint8Array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            // Create Blob
            var blob = new Blob([uint8Array], { type: 'image/png' });
            // Call the async function to handle image upload
            await handleImageUpload(blob);

        } catch (error) {
            // Handle the error here
            console.error("Error cropping image:", error);
        }
    }
    

    const handleImageChange = () => {
        setShowModal(true);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "0",
        },
    };

    function closeModal() {
        if (showModal) {
            window.location.reload();
        }
        setShowModal(false);
    }
    
    const onClose = () => {
        closeModal();
    }

    return (
        <div className="webapp-cardhistory">
            <Sidebar selectedOption="profile" />
            <div className="webapp-coachprofile-content">
                <button className="webapp-coachprofile-logout" onClick={logoutUser}>{t('Log Out')}</button>
                <div className="webapp-coachprofile-header">
                {coachImage === null ?
                        <div className="webapp-coachprofile-header-img-null">
                            <img className='webapp-coachprofile-img-null' src={ImagePlus} alt={`Profile image of ${coachData.first_name}`} onClick={handleImageChange} />
                        </div>
                        :
                        <div className="webapp-coachprofile-header-img">
                            <img className="webapp-coachprofile-img" src={coachImage} alt={`Profile image of ${coachData.first_name}`} onClick={handleImageChange} />
                        </div>
                    }
                    {showModal && (
                        <Modal
                            isOpen={showModal}
                            onRequestClose={() => setShowModal(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Avatar
                                width={390}
                                height={295}
                                onCrop={onCrop}
                                onClose={onClose}
                                label={t('Choose a file')}
                            />
                        </Modal>
                    )}
                    <div className="webapp-coachprofile-header-text">
                        <h1>{t('Coach')}</h1>
                        {coachData.first_name === undefined ?
                            <TextPlaceHolder fontSize={1} />
                            :
                            <p>{`${coachData.first_name} ${coachData.last_name}`}</p>
                        }
                    </div>
                </div>
                <h2 className="webapp-cardhistory-title">{t('Payments Data')}</h2>
                <div className="webapp-cardhistory-section">
                    <h3 className="webapp-cardhistory-section-title">{t('Current Card')}</h3>
                    <div className="webapp-cardhistory-section-content">
                        <div className="webapp-cardhistory-section-cards">
                            {methods.length === 0 && subscription.default_payment_method === undefined ? // Two conditions to render at the same time
                                <TextPlaceHolder fontSize={2} width={'40vw'} />
                                :
                                <CardInfo info={subscription.default_payment_method} />}
                        </div>
                        <div className="webapp-cardhistory-section-buttons webapp-cardhistory-section-buttons-change">
                            <button className={`webapp-cardhistory-section-button webapp-cardhistory-section-change ${changeMode && "webapp-cardhistory-section-change-active"}`}
                                onClick={() => handleChangeMode("change")}><img src={cardicon} /><h4>{t('Change Card')}</h4></button>
                            {changeMode &&
                                <>
                                    <button className="webapp-cardhistory-section-button webapp-cardhistory-section-change" onClick={() => handleChangeMode("save")}><h4>{t('Save Changes')}</h4></button>
                                    <button className="webapp-cardhistory-section-button webapp-cardhistory-section-cancel" onClick={() => handleChangeMode("cancel")}><h4>{t('Cancel')}</h4></button>
                                </>}
                        </div>
                    </div>
                </div>
                <div className="webapp-cardhistory-section">
                    <h3 className="webapp-cardhistory-section-title">{t('Associated Cards')}</h3>
                    <div className="webapp-cardhistory-section-content">
                        <div className="webapp-cardhistory-section-cards">
                            {methods.length === 0 && subscription.default_payment_method === undefined ? (
                                <div className="webapp-cardhistory-section-cards">
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                </div>
                            ) : (
                                methods.length === 0 && subscription.default_payment_method !== undefined ? (
                                    <CardInfo info={subscription.default_payment_method} />
                                ) : (
                                    Object.keys(methods).map((key, index) => (
                                        <CardInfo
                                            info={methods[key]}
                                            selected={methods[key]?.id === selected?.id}
                                            key={index}
                                            action={setSelected}
                                            mode={changeMode}
                                            deleteM={deleteMode}
                                            setPopup={handlePopup}
                                            setCardToDelete={setCardToDelete}
                                        />
                                    ))
                                )
                            )}

                        </div>
                        <div className="webapp-cardhistory-section-buttons">
                            <button className="webapp-cardhistory-section-button webapp-cardhistory-section-add" onClick={addCard}><img src={cardiconplus} /><h4>{t('Add New Card')}</h4></button>
                            {methods.length > 0 &&
                                <button className={`webapp-cardhistory-section-button webapp-cardhistory-section-remove ${deleteMode && "webapp-cardhistory-section-remove-active"}`}
                                    onClick={() => setDeleteMode(!deleteMode)}><img src={cardiconx} /><h4>{t('Remove Card')}</h4></button>}
                        </div>
                    </div>
                </div>
                <div className="webapp-cardhistory-section">
                    <div className="webapp-cardhistory-section-header">
                        <h3 className="webapp-cardhistory-section-title">{t('Payments History')}</h3>
                        <Link to="fullhistory" className="webapp-cardhistory-section-history">{t('See Full History')}</Link>
                    </div>
                    <div className="webapp-cardhistory-section-content">
                        <div className="webapp-cardhistory-section-cards">
                            {history === null ?
                                <div className="webapp-cardhistory-section-cards">
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                    <TextPlaceHolder fontSize={2} width={'40vw'} />
                                </div>
                                :
                                history.map((item, index) => {
                                    if (index < 3) {
                                        return (
                                            <PastPurchases key={index} data={item} />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {popup && <ConfirmationPopup data={{
                image: "close",
                title: "Remover Cartão",
                message: [`Tem a certeza que quer remover o cartão`, `**** **** **** ${card}?`],
                button: "Remover",
                close: "Cancelar",
                advance: "Remover"
            }}
                setState={setPopup}
                action={deleteCard}
            />}
        </div>
    );
};

export default CardHistory;