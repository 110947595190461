import { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import GamesSubpageChanger from "../../webAppComponents/GamesSubpageChanger/GamesSubpageChanger";
import FilterDropdown from "../../webAppComponents/FilterDropdown/FilterDropdown";
import BckLogo from "../../webAppAssets/BackgroundLogo.svg";
import FilterBy from "../../webAppComponents/FilterBy/FilterBy";
import SearchBar from "../../webAppComponents/SearchBar/SearchBar";
import Table from "../../webAppComponents/Table/Table";
import MapTable from "../../webAppComponents/MapTable/MapTable";
import "./GamesIndividualLoadPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const GamesIndividualLoadPage = () => {
  const { t } = useTranslation(["games-individual-load"]);
  const navigate = useNavigate();
  const [selectedSubpage, setSelectedSubpage] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [playerData, setPlayerData] = useState([]);
  const [filteredPlayerData, setFilteredPlayerData] = useState([]);

  const columnNamesGame = [
    t("State"),
    t("Minutes played"),
    t("Yellow cards"),
    t("Red cards"),
    t("Goals"),
    t("Assists"),
  ];
  const columnNamesGeneral = [
    t("Games played"),
    t("Minutes played"),
    t("Yellow cards"),
    t("Red cards"),
    t("Goals"),
    t("Assists"),
  ];

  const mappedColumns = selectedSubpage === 0 ? 
  {
    ItemName: "name",
    [columnNamesGame[0]]: "status",
    [columnNamesGame[1]]: "minutes_played",
    [columnNamesGame[2]]: "yellow_cards",
    [columnNamesGame[3]]: "red_cards",
    [columnNamesGame[4]]: "goals",
    [columnNamesGame[5]]: "assists"
  } 
  : 
  {
    ItemName: "name",
    [columnNamesGeneral[0]]: "games_played",
    [columnNamesGeneral[1]]: "minutes_played",
    [columnNamesGeneral[2]]: "yellow_cards",
    [columnNamesGeneral[3]]: "red_cards",
    [columnNamesGeneral[4]]: "goals",
    [columnNamesGeneral[5]]: "assists"

  };

  const { authTokens, logoutUser } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  useEffect(() => {
    if (selectedSubpage == 0) {
      if (options.length > 0) return;
      axios
        .get(process.env.REACT_APP_BACKEND + `games/list/`, { headers })
        .then((response) => {
          let filterOptions = [];

          response.data.map((game) => {
            filterOptions.push({
              value: game["id"],
              label: game["date"] + " " + game["against"]["name"],
            });
          });

          setOptions(filterOptions);
          setSelectedFilter(filterOptions[0]?.value);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else if (selectedSubpage == 1) {
      // Request Estatisticas gerais - soma das estatisticas de todos os jogos
      axios
        .get(
          process.env.REACT_APP_BACKEND +
            "games/jogador/somaestatisticastotais/",
          { headers },
        )
        .then((response) => {
          let data = response.data;
          setPlayerData(data);
          setFilteredPlayerData(filteredData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedSubpage]);

  useEffect(() => {
    if (selectedSubpage === 1) return;
    // Request Jogo -
    if (selectedFilter === undefined) return;
    axios
      .get(
        process.env.REACT_APP_BACKEND +
          `games/jogador/estatisticastotais/` +
          selectedFilter,
        { headers },
      )
      .then((response) => {
        const data = response.data;
        setPlayerData(data);
        setFilteredPlayerData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedFilter, selectedSubpage]);


  const handleSubpageChange = (subpage) => {
    setSelectedSubpage(subpage);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleAddGame = () => {
    navigate("/webapp/addgame");
  };

  return (
    <div className="webapp-games-page-scroll">
      <Sidebar selectedOption="games" />
      <div className="webapp-games-page">
        <div className="webapp-games-page-subsections">
        <TrainingsPageChanger
            selectedPage={1}
            pages={[
                { label: t("Collective Load"), path: '/webapp/collective-load' },
                { label: t("Individual Load"), path: '/webapp/individual-load' },
                // { label: t('Training Statistics'), path: '/webapp/stats' },
            ]}
          />
        </div>
        <div className="webapp-create-team-background-images">
          <img
            src={BckLogo}
            alt="Logo"
            className="webapp-create-team-bcklogo"
            id="webapp-create-team-bcklogo1"
          />
          <img
            src={BckLogo}
            alt="Logo"
            className="webapp-create-team-bcklogo"
            id="webapp-create-team-bcklogo2"
          />
        </div>
        <div className="webapp-games-page-games-container">
          <div className="webapp-games-folder-container-header">
            <h1>{t("Games - Indivdual load")}</h1>
            <button onClick={() => handleAddGame()}>+ {t("New Game")}</button>
          </div>
          <GamesSubpageChanger
            selectedSubpage={selectedSubpage}
            onSubpageChange={handleSubpageChange}
            page1={t("Games")}
            page2={t("Overall stats")}
          />
          {selectedSubpage === 0 && (
            <div className="webapp-games-page-table-mods">
              { options.length > 0 && ( <FilterBy options={options} onChange={handleFilterChange} /> ) }
              <SearchBar
                playerData={playerData}
                setFilteredPlayerData={setFilteredPlayerData}
                t={t}
              />
            </div>
          )}{" "}
          {selectedSubpage === 1 && (
            <div className="webapp-games-page-table-mods">
              <SearchBar
                playerData={playerData}
                setFilteredPlayerData={setFilteredPlayerData}
                t={t}
              />
            </div>
          )}
          {/* <Table
            columnNames={selectedSubpage === 0 ? columnNamesGame : columnNamesGeneral}
            playerData={filteredPlayerData}
            t={t}
          /> */}
          <MapTable
                  ItemName={t("Name")}
                  Data={filteredPlayerData}
                  mappedColumns={mappedColumns}
                  columnNames={selectedSubpage === 0 ? columnNamesGame : columnNamesGeneral}
                  visibleColumns = {6}
                  t = {t}
          />
        </div>
      </div>
    </div>
  );
};

export default GamesIndividualLoadPage;
