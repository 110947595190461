import "./CreateTeam.css"
import Arrow from "../../webAppAssets/register-arrow-right.svg"
import { useState, useEffect, useContext } from "react";
import PlayerCard from "./PlayerCard";
import BckLogo from "../../webAppAssets/BackgroundLogo.svg"
import EditPencil from "../../webAppAssets/edit-pencil.svg"
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "../../webAppComponents/ConfirmationPopup/Popup";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";


const CreateTeam = (props) => {
    const { t } = useTranslation(['create-team']);
    const [progress, setProgress] = useState(0);
    const [edit, setEdit] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [athletes, setAthletes] = useState([]);
    const navigate = useNavigate();
    const [state, setPopupstate] = useState(false);
    const [positions, setPositions] = useState([]);
    const [handedness, setHandedness] = useState([]);

    const [athlete, setAthlete] = useState({
        first_name: "",
        email: "",
        last_name: "",
        dateofbirth: "",
        date_of_entry: "",
        position: "",
        quartile: "",
        handedness: "",
        previousTeam: "",

    });

    const { authTokens, logoutUser, createTeamRedirect } = useContext(AuthContext)

    const packagesLanguageDict = {
        PT: {
            header1: "Adicionar equipa",
            header2: "Adicionar jogadores",
            header3: "Concluir",
            team: "EQUIPA",
            description1: "Este será o nome da equipa que estiver selecionada",
            description2: "JOGADOR",
            teamName: "Nome da equipa",
            next: "Próximo",
            playerName: "Nome Próprio",
            playerEmail: "Email",
            playerSurname: "Apelido",
            playerBirthdate: "Data Nascimento",
            playerEntrydate: "Data de Entrada",
            playerPosition: "Posição",
            playerQuartile: "Quartil",
            playerSide: "Lateralidade",
            playerPreviousTeam: "Equipa Anterior",
            addPlayer: "Adicionar mais Jogadores",
            athletes: "JOGADORES",
        }
        , ENG: {
            header1: "Add team",
            header2: "Add players",
            header3: "Finish",
            team: "TEAM",
            description1: "This will be the name of the team that is selected",
            description2: "PLAYER",
            teamName: "Team Name",
            next: "Next",
            playerName: "First Name",
            playerEmail: "Email",
            playerSurname: "Surname",
            playerBirthdate: "Birthdate",
            playerEntrydate: "Entry Date",
            playerPosition: "Position",
            playerQuartile: "Quartile",
            playerSide: "Side",
            playerPreviousTeam: "Previous Team",
            addPlayer: "Add more Players",
            athletes: "ATHLETES",
        }, ES: {
            header1: "Agregar equipo",
            header2: "Agregar jugadores",
            header3: "Terminar",
            team: "EQUIPO",
            description1: "Este será el nombre del equipo que está seleccionado",
            description2: "JUGADOR",
            teamName: "Nombre del equipo",
            next: "Siguiente",
            playerName: "Nombre propio",
            playerEmail: "Email",
            playerSurname: "Apellido",
            playerBirthdate: "Fecha de nacimiento",
            playerEntrydate: "Fecha de entrada",
            playerPosition: "Posición",
            playerQuartile: "Cuartil",
            playerSide: "Lateralidad",
            playerPreviousTeam: "Equipo anterior",
            addPlayer: "Agregar más jugadores",
            athletes: "JUGADORES",
        }
    }

    // Get the positions and handedness from the API
    useEffect(() => {
        const getPositions = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                };
                const response = await Axios.get(process.env.REACT_APP_BACKEND + 'users/positions', config);
                if (response.status === 200) {
                    setPositions(response.data);
                }
            }
            catch (error) {
                console.error('Error fetching positions:', error);
            }
        }

        const getHandedness = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                };
                const response = await Axios.get(process.env.REACT_APP_BACKEND + 'users/handedness', config);
                if (response.status === 200) {
                    setHandedness(response.data);
                }
            }
            catch (error) {
                console.error('Error fetching handedness:', error);
            }
        }

        getPositions();
        getHandedness();
    }, []);


    // Handle the progress bar
    const handleProgress = (e) => {
        e.preventDefault();

        if (e.target.classList.contains("webapp-createteam-button") && progress < 2) {
            if (progress === 0 && document.querySelector(".webapp-teamname-input").value !== "") {
                setTeamName(document.querySelector(".webapp-teamname-input").value)

            } else if (progress === 0 && document.querySelector(".webapp-teamname-input").value === "") {
                document.querySelector(".webapp-teamname-input").classList.add("webapp-createteam-inputs-error")
                document.querySelector('.error-message').classList.add("visible")
                console.log("error")
                return
            }

            if (progress === 1 && athlete.first_name !== "" && athlete.email !== "" && athlete.last_name !== "" && athlete.dateofbirth !== "" && athlete.date_of_entry !== "" && athlete.position !== "" && athlete.quartile !== "" && athlete.handedness !== "" && athlete.previousTeam !== "") {
                addPlayerPlayerPool(e)
            } else if (progress === 1 && (athlete.first_name === "" || athlete.email === "" || athlete.last_name === "" || athlete.dateofbirth === "" || athlete.date_of_entry === "" || athlete.position === "" || athlete.quartile === "" || athlete.handedness === "" || athlete.previousTeam === "")) {
                const inputs = document.querySelectorAll(".webapp-createteam-required");
                const messages = document.querySelectorAll(".error-message");
                inputs.forEach((input, index) => {
                    if (input.value === "") {
                        if (index < inputs.length) {
                            messages[index].classList.add("visible")
                        }
                        input.classList.add("webapp-createteam-inputs-error");
                    }
                })
                return
            }
            setProgress(progress + 1)
        }

        if ((progress === 2 && e.target.classList.contains("webapp-createteam-finish-button"))) {
            setPopupstate(true)
        }
    }

    // Go back to the previous state
    const goBack = (e) => {
        e.preventDefault();
        if ((e.target.classList.contains("webapp-home-button") || e.target.classList.contains("webapp-home-text") || e.target.classList.contains("webapp-blue-arrow")) && progress > 0) {
            setProgress(progress - 1)
        } else if ((e.target.classList.contains("webapp-home-button") || e.target.classList.contains("webapp-home-text") || e.target.classList.contains("webapp-blue-arrow")) && progress === 0) {
            setPopupstate(true)
        }
    }

    const goHome = (e) => {
        e.preventDefault();
        logoutUser();
    }

    // Error messages
    useEffect(() => {
        //select all the webapp-create-team-progress-item
        const progressItems = document.querySelectorAll(".webapp-create-team-progress-item");
        //update the progress bar
        progressItems.forEach((item, index) => {
            if (index === progress) {
                item.classList.add("webapp-create-team-progress-item-active")
            } else {
                item.classList.remove("webapp-create-team-progress-item-active")
            }
        })
    })

    useEffect(() => {
        //if there is text in an input, remove the error class from it
        const inputs = document.querySelectorAll(".webapp-createteam-required");
        const messages = document.querySelectorAll(".error-message");
        inputs.forEach((input, index) => {
            input.addEventListener("input", (e) => {
                if (e.target.value !== "") {
                    e.target.classList.remove("webapp-createteam-inputs-error")
                    messages[index].classList.remove("visible")
                }
            })
        })
    }, [athlete, progress])


    // Update the current athlete
    const handleAthlete = (e) => {
        e.preventDefault();
        var q = athlete.quartile;
        //if the birtdate is not empty, see in which quartile the player is
        if (e.target.name === "dateofbirth" && e.target.value !== "") {
            const birthdate = new Date(e.target.value);
            const month = birthdate.getMonth();
            var q = "";
            if (month >= 0 && month <= 2) {
                q = "Q1"
            } else if (month >= 3 && month <= 5) {
                q = "Q2"
            }
            else if (month >= 6 && month <= 8) {
                q = "Q3"
            }
            else if (month >= 9 && month <= 11) {
                q = "Q4"
            }
        }
        setAthlete({
            ...athlete,
            quartile: q,
            [e.target.name]: e.target.value
        })
    }

    // Add a player to the player pool
    const addPlayerPlayerPool = (e) => {

        e.preventDefault();

        if (athlete.first_name === "" || athlete.email === "" || athlete.last_name === "" || athlete.dateofbirth === "" || athlete.date_of_entry === "" || athlete.position === "" || athlete.quartile === "" || athlete.handedness === "" || athlete.previousTeam === "") {
            const inputs = document.querySelectorAll(".webapp-createteam-required");
            const messages = document.querySelectorAll(".error-message");
            inputs.forEach((input, index) => {
                if (input.value === "") {
                    if (index < inputs.length) {
                        messages[index].classList.add("visible")
                    }
                    input.classList.add("webapp-createteam-inputs-error");
                }
            })
        } else {
            setAthletes([...athletes, athlete])
            setAthlete({
                first_name: "",
                email: "",
                last_name: "",
                dateofbirth: "",
                date_of_entry: "",
                position: "",
                quartile: "",
                handedness: "",
                previousTeam: "",

            });
            //clear the form
            document.querySelector(".webapp-player-info-form").reset();
        }
    }

    // Edit remove state of the player cards
    const handleEdit = (e) => {
        e.preventDefault();
        setEdit(!edit);
    }

    // Remove a player from the player pool
    const removePlayer = (e) => {
        e.preventDefault();
        const index = e.target.getAttribute("cenas");
        const newAthletes = athletes.filter((athlete) => athlete.email !== index);
        setAthletes(newAthletes);
    }

    // Create the team
    const handleSubmit = async () => {
        try {
            const data = {
                team_name: teamName,
                players: athletes,
            };

            // Set the Authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${authTokens.access}`,
                },
            };



            const response = await Axios.post(process.env.REACT_APP_BACKEND + 'users/create-team', data, config);
            if (response.status === 201) {
                createTeamRedirect();
                
            } else {
                console.error('Error creating team:', response.data);
            }
        } catch (error) {
            console.error('Error creating team:', error);
        }
    };

    const handleDoItLater = async (e) => {
        e.preventDefault();
        try {
            const data = {
                team_name: teamName,
                players: [],
            };
    
            // Set the Authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${authTokens.access}`,
                },
            };
    
            // Sending a POST request using Axios
            const response = await Axios.post(process.env.REACT_APP_BACKEND + 'users/create-team', data, config);
    
            // Checking the response status
            if (response.status === 201) {
                createTeamRedirect();          
            } else {
                console.error('Error creating team:', response.data);
            }
        } catch (error) {
            console.error('Error creating team:', error);
        }
    };

    return (
        <div className="webapp-create-team">
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id={`webapp-create-team-bcklogo${progress * 2 + 1}`} />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id={`webapp-create-team-bcklogo${progress * 2 + 2}`} />
            </div>
            <div className="webapp-home-button" onClick={goBack}>
                <img className="webapp-blue-arrow" src={Arrow} alt="Voltar" />
                <p className="webapp-home-text">{t('Go Back')}</p>
            </div>
            <div className="webapp-create-team-content">
                <div className="webapp-create-team-progress">
                    <p className="webapp-create-team-progress-item"> 1 </p>
                    <hr className="webapp-create-team-progress-bar" />
                    <p className="webapp-create-team-progress-item"> 2 </p>
                    <hr className="webapp-create-team-progress-bar" />
                    <p className="webapp-create-team-progress-item"> 3 </p>
                </div>
                {progress === 0 ?
                    <div className="webapp-create-team-content">
                        {state && <Popup data={{ image: "close", message: [t('go-back-popup.message'), t('go-back-popup.message-question')], close: t('go-back-popup.no-option'), advance: t('go-back-popup.yes-option') }} setState={setPopupstate} action={goHome} />}
                        <div className="webapp-create-team-header">
                            <h1>{t('Add a Team')}</h1>
                            <p>{t('Team Name Text')}</p>
                        </div>
                        <form className="webapp-create-team-form">
                            <label className="dark-blue-15px-700">{t('Team Name')} <span className="webapp-asterisco">*</span></label>
                            <input className="webapp-teamname-input webapp-createteam-inputs webapp-createteam-required" type="text" placeholder="Ex.: Académica de Coimbra, Penalvense" />
                            <p className="error-message error-message-createteam"> ⓘ {t('Mandatory Field')}</p>
                            <div className="webapp-createteam-buttons-team-name">
                                <button className="webapp-createteam-button webapp-createteam-blue-button" type="submit" onClick={handleProgress}>{t('Next')}</button>
                            </div>
                        </form>
                    </div>
                    : progress === 1 ?
                        <div className="webapp-create-team-content webapp-create-team-players">
                            <div className="webapp-createteam-teamname">
                                <h4>{t('Team Caps')}: {teamName}</h4>
                            </div>
                            <div className="webapp-create-team-header">
                                <h1>{t('Add Players')}</h1>
                                <p>{t('Player')}{athletes.length + 1}</p>
                            </div>
                            <form className="webapp-player-info-form" onChange={handleAthlete}>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Name')}<span className="webapp-asterisco">*</span></label>
                                    <input className="webapp-createteam-inputs webapp-createteam-required" type="text" placeholder="Ex.: Afonso" name="first_name" />
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Email')}<span className="webapp-asterisco">*</span></label>
                                    <input className="webapp-createteam-inputs webapp-createteam-required" type="email" placeholder="Ex: Nome@gmail.com" name="email" />
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Surname')}<span className="webapp-asterisco">*</span></label>
                                    <input className="webapp-createteam-inputs webapp-createteam-required" type="text" placeholder="Ex.: Silva" name="last_name" />
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-playerinfo-smallinputs-holder">
                                    <div className="webapp-playerinfo-smallinput">
                                        <label className="dark-blue-15px-700">{t('Date of Birth')}<span className="webapp-asterisco">*</span></label>
                                        <input className="webapp-createteam-inputs webapp-createteam-required" type="date" name="dateofbirth" />
                                        <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                    </div>
                                    <div className="webapp-playerinfo-smallinput">
                                        <label className="dark-blue-15px-700">{t('Joining Date')}<span className="webapp-asterisco">*</span></label>
                                        <input className="webapp-createteam-inputs webapp-createteam-required" type="date" name="date_of_entry" />
                                        <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                    </div>
                                </div>

                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Position')}<span className="webapp-asterisco">*</span></label>
                                    <select className="webapp-createteam-inputs webapp-createteam-required" name="position">
                                        <option value="" disabled selected defaultValue={""}>{t("Select an Option")}</option>
                                        {positions.map((position, index) => (
                                            <option key={index} value={position[0]}>{t('Position', { context: position[0] })}</option>
                                        ))}
                                    </select>
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Quartile')}</label>
                                    <input className="webapp-createteam-inputs" type="text" placeholder={athlete.quartile === "" ? `${t('Auto Fill')} (Q1/Q2/Q3/Q4)` : athlete.quartile} disabled name="quartile" />
                                </div>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Handedness')}<span className="webapp-asterisco">*</span></label>
                                    <select className="webapp-createteam-inputs webapp-createteam-required" name="handedness">
                                        <option value="" disabled defaultValue={""} selected>{t("Select an Option")}</option>
                                        {handedness.map((handedness, index) => (
                                            <option key={index} value={handedness[1]}>{t('Handedness', { context: handedness[0] })}</option>
                                        ))}
                                    </select>
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-playerinfo-biginput">
                                    <label className="dark-blue-15px-700">{t('Last Team')}<span className="webapp-asterisco">*</span></label>
                                    <input className="webapp-createteam-inputs webapp-createteam-required" type="text" placeholder="Ex: Farense" name="previousTeam" />
                                    <p className="error-message"> ⓘ {t('Mandatory Field')}</p>
                                </div>
                                <div className="webapp-createteam-buttons">
                                    <div className="webapp-createteam-adding-players">
                                        <button className="webapp-createteam-button webapp-createteam-blue-button" type="submit" onClick={addPlayerPlayerPool}>{t('Add More Players')}</button>
                                        <button className="webapp-createteam-button webapp-createteam-white-button" type="submit" onClick={handleProgress}>{t('Next')}</button>
                                    </div>
                                    <div className="webapp-create-team-doitlater">
                                        <button className="webapp-createteam-doitlater-button" type="submit" onClick={(e) => handleDoItLater(e)}>{t('Do It Later')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="webapp-create-team-content webapp-create-team-players">
                            {state && <Popup data={{ image: "advance", message: [`${t('create-team-popup.message-1')} ${teamName} ${t('create-team-popup.message-2')} ${athletes.length} ${t('create-team-popup.message-3')}`, t('create-team-popup.message-question')], close: t('create-team-popup.no-option'), advance: t('create-team-popup.yes-option') }} setState={setPopupstate} action={handleSubmit} />}
                            <div className="webapp-createteam-teamname">
                                <h4>{t('Team Caps')}</h4>
                            </div>
                            <div className="webapp-create-team-header">
                                <h1>{teamName}</h1>
                                <p>{athletes.length} {athletes.length === 1 ? t('Player') : t('Players')}</p>
                            </div>
                            <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit" type="submit" onClick={handleEdit}> <img src={EditPencil} alt="Edit Button" />{t('Edit')}</button>
                            <div className="webapp-create-team-players-list">
                                {athletes.map((player, index) => (
                                    <PlayerCard player={player} key={index} edit={edit} removePlayer={removePlayer} t={t} />
                                ))}
                                <div className="webapp-createteam-buttons webapp-createteam-finish">
                                    <button className="webapp-createteam-button webapp-createteam-blue-button webapp-createteam-finish-button" type="submit" onClick={handleProgress}>{t('Confirm')}</button>
                                </div>
                            </div>

                        </div>
                }
            </div>
        </div>
    )
}

export default CreateTeam
