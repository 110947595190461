import react, { useState, useEffect, useContext } from 'react'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import "./CreateSessionPopup.css"
import { SessionGeneralData, AssociateTrainingToSession, FinalSessionData } from '../../webAppComponents/CreateSessionPopupItems/CreateSessionPopupItems'
import axios from 'axios'
import AuthContext from '../../context/AuthContext'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'

const CreateSessionPopup = ({ handleReturn }) => {
    const { t } = useTranslation(['create-session-popup']);
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [trainings, setTrainings] = useState([]);
    const [session, setSession] = useState({
        training: [],
    });

    const { authTokens } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };
    const [step, setStep] = useState(1);

    const nextStep = () => {
        if(checkStep())
          setStep(step + 1);
        else
          handleStepError()
    }

    const checkStep= () =>{
      if(step == 1){
        return session.date != undefined
      }else
        return session.training.length > 0
    }

    const handleStepError = () =>{
      if(step == 1)
        toast.error(t('session-date-error'))
      else
        toast.error(t('session-exercise-error'))
    }

    const previousStep = () => {
        if (step === 1)
            return;
        else
            setStep(step - 1);
    }

    const getPageItems = () => {
        if (selectedFolder === null) {
            setCurrentFolder(null)
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder__isnull=True', { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setFolders(response.data)

                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.error(error);
            }
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/exercise/list?folder__isnull=True&archive=False', { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setTrainings(response.data)
                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    })
            }
            catch (error) {
                console.log(error);
            }
        } else {
            // Get the current folder
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/' + selectedFolder, { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setCurrentFolder(response.data)
                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.error(error);
            }
            // Get the folders and trainings inside the selected folder
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder=' + selectedFolder, { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setFolders(response.data)

                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.error(error);
            }
            // Get the trainings inside the selected folder
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/exercise/list?folder=' + selectedFolder + '&archive=False', { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setTrainings(response.data)
                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    })
            }
            catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        getPageItems();

    }, [selectedFolder])

    const handleFolderSelection = (id) => {
        // set selected folder the folder with the same id as the one clicked
        setSelectedFolder(id)
    }

    const handleStartTimeChange = (newTime) =>{
      setSession({
        ...session,
        startTime: newTime
      })
    }

    const handleDateChange = async (e) => {
        const newDate = e.target.value;
        //detect clear
        if(newDate == ''){
          setSession({
            ...session,
            date: undefined,
            microcycle: undefined,
            weekday: undefined,
            number: undefined
          })
          return;
        }

        try {
            // Make an API call to update the status in the backend
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND}training/microcycle-bydate`,
                {
                    headers: headers,
                    params: { date: newDate }
                },
            );
            setSession({
                ...session,
                date: newDate,
                microcycle: response.data.cycle.cycle_number,
                weekday: response.data.weekday,
                number: response.data.sessions_in_cycle + 1,
            });
        } catch (error) {
            console.error('Error updating status in the backend:', error);
            // Handle errors accordingly
        }
    }

    const handleTrainingChange = async (training) => {
        // if the training is already in the session training list, remove it
        if (session.training.find((element) => element.id === training.id)) {
            setSession({
                ...session,
                training: session.training.filter((element) => element.id !== training.id),
            });
        }else{
             // appends training to the session training list
            setSession({
                ...session,
                training: [...session.training, training],
            });
        }
    }

    const handleSubmit = async () => {
        session.exercises = session.training.map((exercise) => exercise.id);
        delete session.training;

        let time_format = session.startTime.split('h');
        time_format = time_format[0] + ':' + (time_format[1] == '' ? '00' : time_format[1]) + ":00"
        session.date = session.date + " " + time_format

        try {
            // Make an API call to update the status in the backend
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND}training/session/create`,
                session,
                { headers: headers }
            );

            if (response.status === 201) {
                handleReturn();
            }
        } catch (error) {
            console.error('Error updating status in the backend:', error);
            // Handle errors accordingly
        }
    }

    return (
        <div className="webapp-create-session-popup">

            <div className="webapp-paint-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                <h1>{t('New Session')}</h1>
            </div>
            <div className="webapp-create-session-popup-steps">
                <div className={`${step === 1 && 'create-training-info-form-selected-step'}`}>1</div>
                <span></span>
                <div className={`${step === 2 && 'create-training-info-form-selected-step'}`}>2</div>
                <span></span>
                <div className={`${step === 3 && 'create-training-info-form-selected-step'}`}>3</div>
            </div>
            <div className="webapp-create-session-popup-content">
                {step === 1 ?
                  <SessionGeneralData key={session} handleChange={handleDateChange} session={session} handleStartTimeChange={handleStartTimeChange} />
                    : step === 2 ?
                        <AssociateTrainingToSession trainings={trainings} currentFolder={currentFolder} folders={folders} handleChange={handleTrainingChange} handleFolderSelection={handleFolderSelection} session={session} />
                        : step === 3 ?
                            <FinalSessionData state={session} />
                            :
                            null
                }
            </div>
            <div className="create-session-popup-buttons">
                {step === 1 ? <button className="create-session-cancel-button" onClick={handleReturn}>Cancelar</button> :
                    <button className="create-session-previous-button" onClick={previousStep}>Anterior</button>}
                {step === 3 ? <button className="create-session-save-button" onClick={handleSubmit} >Guardar Sessão</button> :
                    <button className="create-session-next-button" onClick={nextStep}>Próximo</button>}
            </div>
        </div>
    )
}

export default CreateSessionPopup
