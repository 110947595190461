import react, { useState, useEffect, useContext, useRef } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import FormsRow from "../../webAppComponents/FormsRow/FormsRow";
import FilterBy from "../../webAppComponents/FilterBy/FilterBy";
import "./PlayerFormsPage.css";
import NavbarJogador from "../../webAppComponents/NavbarJogadorQuestionario/NavbarJogadorQuestionario";
import Password from "../../webAppPages/CoachProfile/Password";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PlayerFormsPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const passwordRef = useRef(null);

  const updatePassword = (newValue) => {
    passwordRef.current = newValue;
  };

  const { t, i18n } = useTranslation(["player-forms"]);

  const [selectedSession, setSelectedFilter] = useState(0);
  const [formsData, setFormsData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [displayedFormsData, setDisplayedFormsData] = useState([]);
  const [answered, setAnswered] = useState(false);
  const [selected, setSelected] = useState(0);

  const columnInitial = [
    t("Session"),
    t("Microcicle"),
    t("Status"),
    t("Session Date"),
    t("Exercises"),
    t("Form"),
  ];

  const { authTokens } = useContext(AuthContext);

  // Define the headers with the Bearer token
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    const getMicrocycles = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_BACKEND + "training/player/forms",
          { headers }
        );

        // Create a set of unique microcycle values
        const uniqueMicrocycles = [
          ...new Set(data.map((item) => item.microcycle)),
        ];

        // Map the unique microcycles to the options format
        let newFilterOptions = uniqueMicrocycles.map((microcycle) => {
          // Find the first item with this microcycle
          const item = data.find(
            (dataItem) => dataItem.microcycle === microcycle
          );

          return {
            label: `${item.microcycle_start_date}-${item.microcycle_end_date}`,
            value: item.microcycle,
          };
        });

        // Add the default option
        newFilterOptions.unshift({ label: t("All Microcycles"), value: "all" });

        setFilterOptions(newFilterOptions);
        const sortedData = data.sort((a, b) => a.microcycle - b.microcycle);
        setFormsData(sortedData);
        setDisplayedFormsData(sortedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getMicrocycles();
  }, [i18n.language]); 
  
  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    if (value === "all") {
      setDisplayedFormsData(formsData);
      setSelectedFilter(0);
    } else {
      const filteredData = formsData.filter(
        (session) => session.microcycle === value
      );
      setDisplayedFormsData(filteredData);
    }
  };


  const changePassword = () => {
    const newPassword = { password: passwordRef.current };
    if (passwordRef.current !== null) {
      try {
        axios
          .patch(
            process.env.REACT_APP_BACKEND + "users/change-password",
            newPassword,
            { headers }
          )
          .then((response) => {
            setPassword(false);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Passwords don't match");
    }
  };

  const openPassword = () => {
    setPassword(true);
    //deactivate the scroll in the background
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    if (formsData.length > 0) {
      if (!isMobile) {
        const columnNames = document.querySelectorAll(
          ".webapp-forms-page-player-row-info-answers"
        );
        const rows = document.querySelector(
          ".webapp-forms-page-top-bar-column-name-final"
        );
        rows.style.width = columnNames[0]?.offsetWidth + "px";
      } else {
        const columnNames = document.querySelector(
          ".webapp-forms-page-player-row-info-answers"
        );
        const rows = document.querySelector(
          ".webapp-forms-page-top-bar-column-name-final"
        );
        rows.style.height = columnNames.offsetHeight + "px";
      }
    }
  });

  useEffect(() => {
    setAnswered(
      !formsData[selected]?.pre_train.answered ||
        !formsData[selected]?.post_gym.answered ||
        !formsData[selected]?.post_train.answered
    );
  }, [selected, formsData]);

  const handleButtonClick = () => {
    const { post_gym, post_train, pre_train } = formsData[selected];
    const url = `/webapp/player/QuestionsPlayerConfirmation?ucgym=${post_gym.id}&ucgym_ans=${post_gym.answered}&uctrain=${post_train.id}&uctrain_ans=${post_train.answered}&ucpre=${pre_train.id}&ucpre_ans=${pre_train.answered}`;
    navigate(url);
  };

  return (
    <div className="webapp-forms-page-scroll">
      {password && (
        <Password
          setPassword={setPassword}
          changePassword={changePassword}
          password={passwordRef}
          upDatePass={updatePassword}
        />
      )}
      <NavbarJogador setPassword={openPassword} />
      <div className="webapp-forms-page">
        <div className="webapp-forms-page-header">
          <h1>{t("Your Questionnaire")}</h1>
        </div>
        <FilterBy options={filterOptions} onChange={handleFilterChange} />

        {/* Top bar */}
        <div className="webapp-forms-page-table">
          <div className="webapp-forms-page-top-bar">
            <h4 className="webapp-forms-page-top-bar-column-name-main">
              {t("Session")}
            </h4>

            <h4 className="webapp-forms-page-top-bar-column-name-main">
              {t("Microcycle")}
            </h4>

            <h4 className="webapp-forms-page-top-bar-column-name-date">
              {t("Session Date")}
            </h4>

            <h4 className="webapp-forms-page-top-bar-column-name-train">
              {t("Exercises")}
            </h4>

            <h4 className="webapp-forms-page-top-bar-column-name-final">
              {t("Form")}
            </h4>
          </div>

          {isMobile
            ? // Use displayedFormsData to render data conditionally for mobile
              displayedFormsData[selected] != undefined && (
                <FormsRow
                  session={displayedFormsData[selected]}
                  setSelected={setSelected}
                  size={displayedFormsData.length}
                />
              )
            : displayedFormsData.map((session, index) => (
                <FormsRow key={index} session={session} />
              ))}
        </div>
        {answered && isMobile && (
          <button
            onClick={handleButtonClick}
            className="webapp-forms-page-answers-button"
          >
            Responder
          </button>
        )}
      </div>
    </div>
  );
};

export default PlayerFormsPage;
