import "./DashboardFilters.css";
import { useTranslation } from "react-i18next";

const DashboardFilters = ({ name, filters, handle, type}) => {
    const { t } = useTranslation(["injuries-page"]);

    const handleFilterChange = (e) => {
        const filter = e.target.value;
        handle(name, filter)
    }

    return (
        <div className="webapp-db-filter">
            <p className="webapp-db-filter-name">
                {t(name)}
            </p>
            <div className="webapp-db-filters-clickables">
                {/* <label className="webapp-db-select-all" onClick={handleFilterChange}>
                    <input type="checkbox" className="webapp-db-select-all-input" name="select-all" value="all" />
                    {t("Select All")}
                </label> */}
                {filters.map((filter, index) => {
                    return (
                        <label key={filter.name} className="webapp-db-filter-clickable" onChange={handleFilterChange}>
                            <div className={`webapp-db-filter-${type}`}>
                                <input type={type} id={filter.value} value={filter.value} className={type} name={name}/>
                            </div>
                            {t(filter.label)}
                        </label>
                    );
                })}
            </div>
        </div>
    );
}

export default DashboardFilters;
