import React, { useEffect } from 'react';
import './MapDataRow.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function MapDataRow({data, columns, mappedColumns, detailRoute, t}) {
  const navigate = useNavigate();

  const handleNavigration = (e, id) => {
    if ( detailRoute === undefined ) return;
    
    e.preventDefault();
    e.stopPropagation();
    navigate(`${detailRoute}${id}`);
  }
  return (
    <div className={`webapp-datarow-page-player-row ${detailRoute ? 'selectable' : null}`} onClick={(e) => handleNavigration(e, data?.id)}>
      <div className="webapp-datarow-page-player-row-fix-column">{mappedColumns["ItemName"] ? data[mappedColumns["ItemName"]] : "N/A"}</div>
      <div className="webapp-datarow-page-player-row-items">
        <span className="webapp-datarow-page-player-row-space-filler"></span>
        <div className="webapp-datarow-page-player-row-columns">

          {columns.map((columnName, index) => (
            <div key={index} className="webapp-datarow-page-player-row-info">
              {mappedColumns[columnName] ? t(data[mappedColumns[columnName]]) : "N/A"}
            </div>
          ))}

        </div>
        <span className="webapp-datarow-page-player-row-space-filler"></span>
      </div>
      {/* { detailRoute === undefined ? null : (
        <div className="webapp-datarow-player-row-ver-mais">
        <Link to={`${detailRoute}${data?.id}`}>
          <button>Ver +</button>
        </Link>
      </div>
        )} */}
    </div>
  );
}

export default MapDataRow;
