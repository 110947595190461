import "./GameStats.css";
import axios from "axios";
import { useState, useEffect, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import clock from "../../assets/clock.svg";
import clockB from "../../assets/clockB.svg";
import checked from "../../assets/checked.svg";
import { toast } from 'react-toastify';

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import ConfirmationPopup from "../../webAppComponents/ConfirmationPopup/Popup";

import { HiOutlineArrowLeft } from "react-icons/hi";
import scrollLeft from '../../assets/scroll-left.svg';
import scrollRight from '../../assets/scroll-right.svg';
import plusBlue from '../../webAppAssets/plus_blue.svg';
import minusBlue from '../../webAppAssets/minus_blue.svg';
import { TiTick } from "react-icons/ti";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

import { useTranslation } from "react-i18next";

const GameStats = (props) => {
    const { t } = useTranslation(["gamestats"]);

    const location = useLocation();
    const navigate = useNavigate();
    const { authTokens } = useContext(AuthContext);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [points, setPoints] = useState({
        home: 0,
        away: 0
    });
    const [time, setTime] = useState(null);
    const [firstHalfTime, setFirstHalfTime] = useState(null);
    const [secondHalfTime, setSecondHalfTime] = useState(null);
    const [gameId, setGameId] = useState(location.state.id);

    const [gameStats, setGameStats] = useState(null);
    const [playersGameData, setPlayersGameData] = useState(null);
    const [yellowCards, setYellowCards] = useState(0);

    const [selected, setSelected] = useState("team");
    useEffect(() => {
        const getGameStats = async () => {
        const response = await fetch(
            process.env.REACT_APP_BACKEND + `games/list-games/${gameId}`,
            {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authTokens.access}`,
            },
            }
        );
        const data = await response.json();
        setGameStats(data);
        if (data?.home_game) {
            setPoints({
                home: data?.goals_for,
                away: data?.goals_against
            });
        }else{
            setPoints({
                home: data?.goals_against,
                away: data?.goals_for
            });
        }
        setFirstHalfTime(data?.first_half_duration);
        setSecondHalfTime(data?.second_half_duration);
        const players_game_data_response = await fetch(
            process.env.REACT_APP_BACKEND + `games/players-game-data/${gameId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authTokens.access}`,
                },
            }
        );
        const players_game_data = await players_game_data_response.json();
        setPlayersGameData(players_game_data);
        
        };
        getGameStats();
    }, [authTokens, gameId]);

    const handleReturn = () => {
        navigate("/webapp/collective-load");
    }

    const finishGame = () => {
        updateGameStats();
        if (firstHalfTime === null || firstHalfTime === "" || secondHalfTime === null || secondHalfTime === "") {
            return;
        }

        axios.patch(process.env.REACT_APP_BACKEND + `games/finish-game/${gameId}`, {
            first_half_duration: firstHalfTime,
            second_half_duration: secondHalfTime,
        }, {
            headers: {
                Authorization: `Bearer ${authTokens.access}`,
            }
        })

        navigate("/webapp/collective-load");
    }

    const handlePoints = (team, operation) => {
        if (team === "home") {
            if (operation === "add") {
                setPoints({
                    home: points.home + 1,
                    away: points.away
                });
            } else if (operation === "sub") {
                if (points.home !== 0) {
                    setPoints({
                        home: points.home - 1,
                        away: points.away
                    });
                }
            }
        } else if (team === "away") {
            if (operation === "add") {
                setPoints({
                    home: points.home,
                    away: points.away + 1
                });
            } else if (operation === "sub") {
                if (points.away !== 0) {
                    setPoints({
                        home: points.home,
                        away: points.away - 1
                    });
                }
            }
        }
    }

    const handleTimeChange = (e, half) => {
        if (e.target.value > 99) {
            // Limits input to two digits
            e.target.value = e.target.value.slice(0, -1);
        }

        if (half === "first") {
            setFirstHalfTime(e.target.value);
        }else{
            setSecondHalfTime(e.target.value);
        }
    }

    const updateGameStats = async () => {
        const response = await fetch(
            process.env.REACT_APP_BACKEND + `games/update-game/${gameId}`,
            {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`,
            },
            body: JSON.stringify({
                goals_for: gameStats?.home_game ? points.home : points.away,
                goals_against: gameStats?.home_game ? points.away : points.home,
                players_game_data: playersGameData,
            }),
            }
        );

        if(response.status == 200) toast.success(t("game-stats-saved"));
    }

    const gameStatusOptions = (status) => {
        switch (status) {
            case "toplay":
                return (
                    <div className="webapp-gamestats-status-scheduled">
                        <div>
                            <img src={clock} />
                            <span>{t(status)}</span>
                        </div>
                    </div>
                );
            case "ongoing":
                return (
                    <div className="webapp-gamestats-status-toplay">
                        <div>
                            <img src={clock} />
                            <span>{t(status)}</span>
                        </div>
                    </div>
                );
            case "finished":
                return (
                    <div className="webapp-gamestats-status-finished">
                        <div>
                            <img src={checked}/>
                            <span>{t(status)}</span>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="webapp-gamestats-status-toplay">
                        <div>
                            <img src={clock} />
                            <span>{t(status)}</span>
                        </div>
                    </div>
                );
        }
    }

    const updateDate = (date) => {
        if (!date) return "";
        // changes date of pattern "YYYY-MM-DD" to "DD/MM/YYYY"
        const dateArray = date.split("-")
        return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]
    }

    

    // o que fazer, criar um dataset com a data dos jogadores, e dps criar uma funcao q vai buscar o id do jogador q se esta a mudar e 
    // mudar o valor do dataset e dps fazer um post com esse dataset     
    // Em cada input temos que enviar o id do jogador, o id do jogo, e o valor do input e o valor a mudar ( enviar o dataset basicamente )
    // No fim fazer um post com o dataset todo

    // Individual
    const [firstVisibleColumnIndex, setFirstVisibleColumnIndex] = useState(0);
    const columnNames = ["STATE", "MINUTES PLAYED", "YELLOW CARD", "RED CARD", "GOAL ASSISTS", "GOALS"]

    const handleScrollLeft = () => {
        if (firstVisibleColumnIndex > 0) {
            setFirstVisibleColumnIndex(firstVisibleColumnIndex - 1);
        }
    };

    const handleScrollRight = () => {
        const maxVisibleColumnIndex = columnNames.length - 4;
        if (firstVisibleColumnIndex < maxVisibleColumnIndex) {
            setFirstVisibleColumnIndex(firstVisibleColumnIndex + 1);
        }
    };

    const handleDataChange = (playerId, columnName, value) => {
        if (columnName === "minutes_played" && value < 0) {
            return;
        }

        if (columnName === "yellow_cards") {
            if (value < 0) {
                return;
            }else if (value > 2) {
                return;
            }
        }

        // find the player in the playersGameData array
        const playerIndex = playersGameData.findIndex((player) => player.id === playerId);

        // update the value of the column
        const updatedData = [...playersGameData];
        updatedData[playerIndex][columnName] = value;
        setPlayersGameData(updatedData);
    };

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-gamestats">
            <Sidebar />
            {showConfirmationPopup && <ConfirmationPopup data={{
                title: t("save-results"),
                message: [t("save-results-popup-message")],
                close: t("no"),
                advance: t("yes")
            }} setState={setShowConfirmationPopup} action={() => { updateGameStats()}}/>}
            <div className="webapp-gamestats-container">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <div className="webapp-gamestats-header">
                    <div className="webapp-gamestats-return ">
                        <button onClick={handleReturn}><HiOutlineArrowLeft/></button>
                        <h1>{t('game')}</h1>
                        {gameStats?.status && gameStatusOptions(gameStats?.status)}
                        <dvi>
                            <img src={clockB}/>
                            <span>{gameStats?.duration}min</span>
                        </dvi>
                        <span >{updateDate(gameStats?.date)}</span>
                    </div>
                    <div className="webapp-gamestats-game-info">
                        <h2>{gameStats?.league}</h2>
                        <div className="webapp-gamestats-game-teams">
                            <div className="webapp-gamestats-game-teams-info">
                                <div className="webapp-gamestats-game-teams-home-color"/>
                                <h2>{gameStats?.home_game ? gameStats?.my_team : gameStats?.against}</h2>
                            </div>
                            <h2>vs</h2>
                            <div className="webapp-gamestats-game-teams-info">
                                <div className="webapp-gamestats-game-teams-away-color"/>
                                <h2>{gameStats?.home_game ? gameStats?.against : gameStats?.my_team}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="webapp-gamestats-body">
                    <div className="webapp-gamestats-body-menu">
                        <h2 className={`webapp-gamestats-body-menu-title ${selected === "individual" && "webapp-gamestats-body-menu-title-selected"}`} onClick={() => setSelected("individual")}>{t("individual-load")}</h2>
                        <h2 className={`webapp-gamestats-body-menu-title ${selected === "team" && "webapp-gamestats-body-menu-title-selected"}`} onClick={() => setSelected("team")}>{t("collective-load")}</h2>
                    </div>
                    {selected === "team" &&
                        <div className="webapp-gamestats-body-content">
                            <button className="webapp-gamestats-body-content-button" onClick={() => setShowConfirmationPopup(true)}>{t("save-results")}</button>
                            <h1 className="webapp-gamestats-body-content-title">{t("goals")}</h1>
                            <div className="webapp-gamestats-body-content-score">
                                <div className="webapp-gamestats-body-content-points webapp-gamestats-body-content-home">
                                    <div className="webapp-gamestats-body-content-points-title">
                                        <p className="webapp-gamestats-body-content-points-title-location">{t("home")}</p>
                                        <p className="webapp-gamestats-body-content-points-title-name">{gameStats?.home_game ? gameStats?.my_team : gameStats?.against}</p>
                                    </div>
                                    <div className="webapp-gamestats-body-content-points-content">
                                        <p className="webapp-gamestats-body-content-points-content-button" onClick={() => handlePoints("home", "sub")}>–</p>
                                        <h1 className="webapp-gamestats-body-content-points-content-points">{points.home}</h1>
                                        <p className="webapp-gamestats-body-content-points-content-button" onClick={() => handlePoints("home", "add")}>+</p>
                                    </div>
                                </div>
                                <div className="webapp-gamestats-body-content-points webapp-gamestats-body-content-adversary">
                                    <div className="webapp-gamestats-body-content-points-title">
                                        <p className="webapp-gamestats-body-content-points-title-location">{t("away")}</p>
                                        <p className="webapp-gamestats-body-content-points-title-name">{gameStats?.home_game ? gameStats?.against : gameStats?.my_team}</p>
                                    </div>
                                    <div className="webapp-gamestats-body-content-points-content">
                                        <p className="webapp-gamestats-body-content-points-content-button" onClick={() => handlePoints("away", "sub")}>–</p>
                                        <h1 className="webapp-gamestats-body-content-points-content-points">{points.away}</h1>
                                        <p className="webapp-gamestats-body-content-points-content-button" onClick={() => handlePoints("away", "add")}>+</p>
                                    </div>
                                </div>
                            </div>
                            <div className="webapp-gamestasts-body-periods-time">
                                <div>
                                    <h3 className="webapp-gamestats-body-content-subtitle">{t('first-half')}</h3>
                                    <input type="number" value={firstHalfTime} onChange={(e) => handleTimeChange(e, "first")}></input>
                                    <img className="webapp-gamestats-period-clock" src={clockB}/>
                                </div>
                                <div>
                                    <h3 className="webapp-gamestats-body-content-subtitle">{t('second-half')}</h3>
                                    <input type="number" value={secondHalfTime} onChange={(e) => handleTimeChange(e)}></input>
                                    <img className="webapp-gamestats-period-clock" src={clockB} />
                                </div>
                            </div>
                        </div>}
                    {selected === "individual" &&
                        <div className="webapp-gamestats-body-content">
                            <button className="webapp-gamestats-body-content-button" onClick={() => setShowConfirmationPopup(true)}>{t("save-results")}</button>
                            <div className='webapp-gamestats-individual-page-table'>
                                <div className="webapp-gamestats-individual-page-top-bar">
                                    <div className="webapp-games-page-top-bar-fixed-column">{t("name")}</div>
                                    <div className="webapp-gamestats-individual-scroll-items">
                                        <img
                                            src={scrollLeft}
                                            alt="Scroll Left"
                                            className="webapp-games-top-bar-scroll-left"
                                            onClick={handleScrollLeft}
                                        />

                                        <div className="webapp-gamestats-page-items">
                                            {columnNames.slice(firstVisibleColumnIndex, firstVisibleColumnIndex + 4).map((columnName, index) => (
                                                <span key={index} className="webapp-games-page-top-bar-column-name game-stats-table-indivual">
                                                    {t(columnName)}
                                                </span>
                                            ))}
                                        </div>

                                        <img
                                            src={scrollRight}
                                            alt="Scroll Right"
                                            className="webapp-games-top-bar-scroll-right"
                                            onClick={handleScrollRight}
                                        />
                                    </div>
                                </div>
                                {playersGameData && playersGameData.map((data, index) => (
                                    <div key={index} className="webapp-gamestats-individual-table-item">
                                    <div className="webapp-gamestats-individual-table-item-fixed-column">{data?.name }</div>
                                    <div className="webapp-gamestats-individual-scroll-items">
                                        <img
                                            src={scrollLeft}
                                            alt="Scroll Left"
                                            className="webapp-games-top-bar-scroll-left hidden"
                                        />
                                        <div className="webapp-gamestats-page-items">
                                            {columnNames.slice(firstVisibleColumnIndex, firstVisibleColumnIndex + 4).map((columnName, index) => {
                                                if (columnName === "STATE") {
                                                    return (
                                                        <div className="webapp-gamestats-column-item-container">
                                                            <GameStatsCustomInputs index={index} type="dropdown" data={data} handleDataChange={handleDataChange}/>
                                                        </div>
                                                    )
                                                }
                                                switch (columnName) {
                                                    case "MINUTES PLAYED":
                                                        return (
                                                            <div className="webapp-gamestats-column-item-container">
                                                                <GameStatsCustomInputs index={index} type="number" placeholder="Minutos" data={data} handleDataChange={handleDataChange}/>
                                                            </div>
                                                        )
                                                    case "YELLOW CARD":
                                                        return (
                                                            <div className="webapp-gamestats-column-item-container">
                                                                <GameStatsCustomInputs index={index} type="counter" currentCount={data.yellow_cards} counter_type={"yellow_cards"} data={data} handleDataChange={handleDataChange}/>
                                                            </div>
                                                        
                                                        )
                                                    case "RED CARD":
                                                        return (
                                                            <div className="webapp-gamestats-column-item-container">
                                                                <GameStatsCustomInputs index={index} type="checkbox" data={data} handleDataChange={handleDataChange}/>
                                                            </div>
                                                        )
                                                    case "GOAL ASSISTS":
                                                        return (
                                                            <div className="webapp-gamestats-column-item-container">
                                                                <GameStatsCustomInputs index={index} type="counter" currentCount={data.assists} counter_type={"assists"} data={data} handleDataChange={handleDataChange} />
                                                            </div>
                                                        )
                                                    case "GOALS":
                                                        return (
                                                            <div className="webapp-gamestats-column-item-container">
                                                                <GameStatsCustomInputs index={index} type="counter" currentCount={data.goals} counter_type={"goals"} data={data} handleDataChange={handleDataChange}/>
                                                            </div>
                                                        )
                                                }
                                            })}
                                        </div>
                                        <img
                                            src={scrollRight}
                                            alt="Scroll Right"
                                            className="webapp-games-top-bar-scroll-right hidden"
                                        />
                                    </div>
                                </div>
                                ))}    
                            </div>
                        </div>

                    }
                </div>
            </div>
            <button className={`webapp-gamestats-finish ${!time && "webapp-gamestats-finish-hidden"}`} onClick={finishGame}>{t("end-game")}</button>
        </div>
    );
}



const GameStatsCustomInputs = (props) => {
    const { t } = useTranslation(["gamestats"]);
    const [checkboxClicked, setCheckboxClicked] = useState(props.data.red_cards);

    const handleCheckboxClick = () => {
        props.handleDataChange(props.data.id, "red_cards", !checkboxClicked);
        setCheckboxClicked(!checkboxClicked);
    }


    return (
        <div className="game-stats-custom-input">
            {props.type === "dropdown" ? (
                <select key={props.index} onChange={(e) => props.handleDataChange(props.data.id, "status", e.target.value)}>
                    <option value="starter" selected={props.data.status === "Starter"}>{t("Starter")}</option>
                    <option value="substitute" selected={props.data.status === "Substitute"}>{t("Substitute")}</option>
                    <option value="injured" selected={props.data.status === "Injured"}>{t("Injured")}</option>
                    <option value="not mentioned" selected={props.data.status === "Not Mentioned"}>{t("Not Mentioned")}</option>
                </select>
            ) : 
            props.type === "number"
            ? (
                <input 
                    key={props.index} 
                    type="number"
                    placeholder={props.placeholder} 
                    onChange={(e) => { props.handleDataChange(props.data.id, "minutes_played", e.target.value) }}
                    value = {props.data.minutes_played}
                />
            )
            : props.type==="counter" ? (
                <div className="game-stats-counter-container">
                    <img src={minusBlue} alt="Sub" onClick={() => props.handleDataChange(props.data.id, props.counter_type, props.currentCount-1)} />
                    <span>{props.currentCount}</span>
                    <img src={plusBlue} alt="Add" onClick={() => props.handleDataChange(props.data.id, props.counter_type, props.currentCount+1)} />
                </div>
            ) : 
            props.type==="checkbox" ? 
            (
                <span
                    className="game-stats-custom-checkbox"
                    style={{backgroundColor: checkboxClicked ? "#6978F9" : ""}}
                    onClick={() => handleCheckboxClick()}
                    >
                    {checkboxClicked ? <TiTick style={{ color: "white" }} /> : null}
                </span>

            ) : null
            }
        </div>

    )
}




export default GameStats;




