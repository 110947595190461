import "./ucStats.css"
import { useEffect, useState } from "react";
import Clock from "../../webAppAssets/Clock.svg"
import EditPencil from "../../webAppAssets/edit-pencil.svg"

const UCStats = (props) => {
    const [playersData, setPlayersData] = useState(props.UCStatsData.PlayersData);
    const [UCStatsData, setUCStatsData] = useState(props.UCStatsData.AvgData);
    useEffect(() => {
        setPlayersData(props.UCStatsData.PlayersData)
        setUCStatsData(props.UCStatsData.AvgData)
    })

    const handleChangePse = (player, event) => {
        player = playersData.find((p) => p.player_id === player.player_id)
        player.uctotal_pse = event.target.value
        setPlayersData([...playersData])
        props.setPlayersChanged([...props.playersChanged, player])
    }

    const handleChangePseGym = (player, event) => {
        player = playersData.find((p) => p.player_id === player.player_id)
        player.ucgym_pse = event.target.value
        setPlayersData([...playersData])
        props.setPlayersChanged([...props.playersChanged, player])
    }

    const handleInputChange = (player, event) => {
        event.preventDefault();
        // Get the raw input value (assuming it's in the format hh:mm:ss)
        const input = event.target.value;
        // Compare with the previous value, to check if the user is adding or removing characters
        const inputLength = input.length;
        const prevInputLength = formatTime(player.ucgym_time).length;
        // If the user is removing characters
        if (inputLength < prevInputLength) {
            // If there are 2 colons, replace the deleted character with a 0
            if (input.split(':').length - 1 === 2) {
                // Slice the input on ':'
                const splitInput = input.split(':');
                const splitPrevInput = formatTime(player.ucgym_time).split(':');
                // If an index is empty, replace it with a 0
                if (splitInput[0] === '') splitInput[0] = '0';
                if (splitInput[1] === '') splitInput[1] = '0';
                if (splitInput[2] === '') splitInput[2] = '0';
                const totalSeconds = parseInt(splitInput[0] * 3600) + parseInt(splitInput[1] * 60) + parseInt(splitInput[2]);
                // update the state
                player = playersData.find((p) => p.player_id === player.player_id)
                player.ucgym_time = totalSeconds
                setPlayersData([...playersData])
                props.setPlayersChanged([...props.playersChanged, player])
            }else return;
        }else{
            // The user is adding characters
            const splitInput = input.split(':');
            // Check if an index has more than 2 characters
            const moreThanTwo = splitInput.some((el) => el.length > 2);
            if (moreThanTwo) return;
            const totalSeconds = parseInt(splitInput[0] * 3600) + parseInt(splitInput[1] * 60) + parseInt(splitInput[2]);
            // update the state
            player = playersData.find((p) => p.player_id === player.player_id)
            player.ucgym_time = totalSeconds
            setPlayersData([...playersData])
            props.setPlayersChanged([...props.playersChanged, player])
        }
    };

    const formatTime = (time) => {
        // from seconds to hh:mm:ss
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time - (hours * 3600)) / 60);
        let seconds = time - (hours * 3600) - (minutes * 60);
        return (`${hours}:${minutes}:${seconds}`);
    };

    return (
        <div className="webapp-ucstats-container">
            <div className="webapp-ucstats-section">
                <div className="webapp-ucstats-section-header">
                    <h1>{props.t('Total Load Unit')}</h1>
                </div>
                <div className="webapp-ucstats-section-header">
                    <h1>  {props.t('Gym Load Unit')} </h1>
                </div>
            </div>
            <div className="webapp-ucstats-section">
                <div className="webapp-ucstats-bottom-first">
                    <h2 className="webapp-uc-stats-first-name">{props.t('Name')}</h2>
                    {playersData?.map((player) => {
                        return (
                            <div className="webapp-uc-stats-first-value">
                                <p>{player.player_name}</p>
                                <p>{player.player_position}</p>
                            </div>
                        )
                    })}
                    <h3 className="webapp-uc-stats-first-value">{('Z-Avarage')}</h3>
                </div>
                <div className="webapp-ucstats-bottom-columns uctotal-columns">
                    <div className="webapp-ucstats-bottom-columns-indentifiers">
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('SPE')}</h2>
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('Time')}</h2>
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('SI')}</h2>
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('LU')}</h2>
                    </div>
                    <div className="webapp-ucstats-bottom-columns-values-list">
                        {playersData?.map((player) => {
                            if (player.uctotal_pse == null) {
                                player.uctotal_pse = "0"
                            }
                            if (!props.edit) {
                                return (
                                    <div className="webapp-ucstats-bottom-columns-values">
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_pse ? player.uctotal_pse : "0.00"}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_time ? formatTime(player.uctotal_time) : "0.00"}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_ie ? player.uctotal_ie : "0.00"}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_uc ? player.uctotal_uc : "0.00"}</p>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="webapp-ucstats-bottom-columns-values">
                                        <input
                                            className="webapp-uc-stats-bottom-columns-value-input"
                                            type="number"
                                            placeholder={player.uctotal_pse}
                                            value={player.uctotal_pse}
                                            onChange={(event) => handleChangePse(player, event)}
                                        />
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_time ? formatTime(player.uctotal_time) : "0.00"}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_ie ? player.uctotal_ie : "0.00"}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.uctotal_uc ? player.uctotal_uc : "0.00"}</p>
                                    </div>

                                )
                            }
                        })}
                        <div className="webapp-ucstats-bottom-columns-values">
                            {UCStatsData && (
                                <>
                                    <p className="webapp-uc-stats-bottom-columns-value">{UCStatsData.UcTotalPseAverage ? UCStatsData.UcTotalPseAverage : "0.00"}</p>
                                    <p className="webapp-uc-stats-bottom-columns-value">{UCStatsData.UcTotalTimeAverage ? formatTime(UCStatsData.UcTotalTimeAverage) : "0.00"}</p>
                                    <p className="webapp-uc-stats-bottom-columns-value"> {UCStatsData.UcTotalIeAverage ? UCStatsData.UcTotalIeAverage : "0.00"} </p>
                                    <p className="webapp-uc-stats-bottom-columns-value">{UCStatsData.UcTotalUCAverage ? UCStatsData.UcTotalUCAverage : "0.00"}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="webapp-ucstats-bottom-columns">
                    <div className="webapp-ucstats-bottom-columns-indentifiers">
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('SPE')}</h2>
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('Time')}</h2>
                        <h2 className="webapp-uc-stats-bottom-columns-name">{props.t('LU')}</h2>
                    </div>
                    <div className="webapp-ucstats-bottom-columns-values-list">
                        {playersData?.map((player) => {
                            if (player.ucgym_pse == null) {
                                player.ucgym_pse = "0"
                            }
                            if (!props.edit) {
                                return (
                                    <div className="webapp-ucstats-bottom-columns-values">
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.ucgym_pse}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{formatTime(player.ucgym_time)}</p>
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.ucgym_uc}</p>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="webapp-ucstats-bottom-columns-values">
                                        <input
                                            className="webapp-uc-stats-bottom-columns-value-input"
                                            type="number"
                                            placeholder={player.ucgym_pse}
                                            value={player.ucgym_pse}
                                            onChange={(event) => handleChangePseGym(player, event)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="0"
                                            value={formatTime(player.ucgym_time)}
                                            onChange={(event) => handleInputChange(player, event)}
                                            className="webapp-uc-stats-bottom-columns-value-input webapp-uc-stats-bottom-columns-value-input-time"
                                        />
                                        <p className="webapp-uc-stats-bottom-columns-value">{player.ucgym_uc}</p>
                                    </div>

                                )
                            }

                        })}
                        <div className="webapp-ucstats-bottom-columns-values">
                            {UCStatsData && (
                                <>
                                    <p className="webapp-uc-stats-bottom-columns-value">{UCStatsData.UcGymPseAverage ? UCStatsData.UcGymPseAverage : "0.00"}</p>
                                    <p className="webapp-uc-stats-bottom-columns-value">{formatTime(UCStatsData.UcGymTimeAverage)}</p>
                                    <p className="webapp-uc-stats-bottom-columns-value">{UCStatsData.UcGymUCAverage ? UCStatsData.UcGymUCAverage : "0.00"}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default UCStats;