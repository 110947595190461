import { useState, useRef } from "react";
import EditPencil from "../../webAppAssets/edit-pencil.svg"

const UserInfo = ({ label, value, name }) => {
    const inputRef = useRef(null);
    const [input, setInput] = useState(value)
    // Function to enable the input
    const enableInput = () => {
        // Access the input element using the ref and update its 'disabled' property
        if (inputRef.current) {
            inputRef.current.disabled = !inputRef.current.disabled;

            // You can also perform additional actions on the input element here
            // For example, you might want to focus on the input
            inputRef.current.focus();
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value)
    }

    return (
        <div className="webapp-coachprofile-userinfo">
            <div className="webapp-coachprofile-userinfo-text">
                <h3 className="webapp-coachprofile-userinfo-label">{label}</h3>
                <input type={label == "Password" ? "password" : "text"} className="webapp-coachprofile-userinfo-input" disabled ref={inputRef} value={input} name={name} onChange={handleInputChange} />
            </div>
            <img className="webapp-coachprofile-userinfo-img" src={EditPencil} onClick={enableInput} alt="Button to edit info" />
        </div>
    )
}

export default UserInfo