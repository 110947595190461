import "./CreateSessionPopupItems.css"
import { Folder, Training } from '../TrainingExerciseFolders/TrainingExerciseFolders'
import GameDropdown from '../GameDropdown/GameDropdown';
import  { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';
import FolderBreadCrumb from '../FoldersBreadCrumb/FoldersBreadCrumb';
import { useTranslation } from 'react-i18next';



const SessionGeneralData = (props) => {
    const { t } = useTranslation(['create-session-popup']);
    const [selectedStartTime, setSelectedStartTime]=useState('12h');

    useEffect(() => {
      props.handleStartTimeChange(selectedStartTime)
    }, [selectedStartTime])

    return (
        <div className="create-session-content-items">
            <h3>{t('General Data')}</h3>
            <div className="session-general-data-inputs">
                <div>
                    <label className="black-16px-700">{t('Session Date')}<span className='webapp-asterisco'>*</span></label>
                    <input onChange={props.handleChange} type="date" value={props.session?.date} className='session-data-date'></input>
                </div>
                <div className='webapp-create-session'>
                  <label className="black-16px-700">{t('Start hour')}<span className='webapp-asterisco'>*</span></label>
                  <GameDropdown selected={selectedStartTime} setSelected={setSelectedStartTime} />
                </div>
                <div>
                    <label className="black-16px-700">{t('Session')}</label>
                    <p>{props.session.number}</p>
                </div>
                <div>
                    <label className="black-16px-700">{t('Microcycle')}</label>
                    <p>{props.session.microcycle}</p>
                </div>
                <div>
                    <label className="black-16px-700">{t('Week Day')}</label>
                    <p>{props.session.weekday}</p>
                </div>
            </div>

        </div>

    )
}


const AssociateTrainingToSession = (props) => {
    const { t } = useTranslation(['create-session-popup']);

    const handleSelection = (selected) => {
        props.handleChange({id: selected.id, name: selected.name})
    }

    const handleBreadCrumbNavigate = (folder) => {
        if (folder === null) {
            props.handleFolderSelection(null)
        }else{
            props.handleFolderSelection(folder.id)
        }
    }

    const handleFolderSelection = (id) => {
        props.handleFolderSelection(id)
    }

    const checkIfContains = (id) => {
        if (props.session.training.length === 0) {
            return false
        }
        else {
            // use find function to check if the id is in the array
            const treino = props.session.training.find((element) => element.id === id)
            if (treino) {
                return true
            }
        }

        return false
    }

    return (
        <div className="create-session-content-items">
            <div className="create-session-content-items-header">
                <h3>{t('Associate Exercises To Session')}</h3>
                <h6>{t('Please Select Exercises')}</h6>
                <h5>{t('You Have Selected')} {props.session.training.length} {t('Exercises')}</h5>
            </div>
            <div className="create-session-associate-training">
                <div className='create-session-training-items'>
                    <FolderBreadCrumb folder={props.currentFolder} handleBreadCrumbNavigate={handleBreadCrumbNavigate} />
                    <div className="create-session-popup-folders">
                    {props.folders.map((folder) => (
                        <Folder key={folder.id} folder={folder} InPopup={true} handleFolderSelection={handleFolderSelection}/>
                    ))}
                    </div>
                </div>
                <div className='create-session-training-items'>
                    <h5>{t('Exercises')}</h5>
                    <div className="create-session-popup-trainings">
                    {props.trainings.map((training) => (
                        <div className={`${checkIfContains(training.id) ? 'create-session-popup-selected-item' : 'abc'}`}>
                        <Training getCurrentRequestedId={()=> handleSelection(training)} key={training.id} training={training} currentTraining={props.session.training?.id} selectable={true}/>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const handleExercisesFormat = (exercises) => {
    let exercisesString = ""
    exercises.forEach((exercise, index) => {
        if (index === exercises.length - 1) {
            exercisesString += exercise.name
        }else{
            exercisesString += exercise.name + ", "
        }
    })
    return exercisesString
}

const FinalSessionData = (props) => {
    const { t } = useTranslation(['create-session-popup']);

    return (
        <div className="create-session-content-items">
            <h3>{t('Final Data')}</h3>
            <div className="create-session-final-data">
                <h4>1. {t('General Data')}</h4>
                <div>
                    <div>
                        <label className="gray-16px-700">{t('Session Date')}</label>
                        <p className="black-16px-600">{props.state.date}</p>
                    </div>
                    <div>
                        <label className="gray-16px-700" >{t('Session Number')}</label>
                        <p className="black-16px-600">{props.state.number}</p>
                    </div>
                    <div>
                        <label className="gray-16px-700" >{t('Microcycle')}</label>
                        <p className="black-16px-600">{props.state.microcycle}</p>
                    </div>

                </div>

            </div>
            <div className="create-session-final-data">
            <h4>2. {t('Exercises Associated')}</h4>
                <div>
                    <div>
                        <label className="gray-16px-700">{t('Exercises')}</label>
                        <p className="black-16px-600">{handleExercisesFormat(props.state.training)}</p>
                    </div>

                </div>
            </div>

        </div>
    )
}



export { SessionGeneralData, AssociateTrainingToSession, FinalSessionData }
