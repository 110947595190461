import React, { useState, useEffect, useContext, Children } from 'react';
import ChartComponent from '../../webAppComponents/ChartsDashboard/index';
import ChartComponent2 from '../../webAppComponents/ChartsDashboard/index2';
import ChartComponent3 from '../../webAppComponents/ChartsDashboard/index3';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import FilterBy from '../../webAppComponents/ChartsDashboard/filter';
import './DashboardPage.css';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';

import IE_icon from '../../webAppAssets/IE_Icon.png';
import Horizontal from '../../webAppComponents/DashboardHorizontal/DashboardHorizontal';
import Vertical from '../../webAppComponents/DashboardVertical/DashboardVertical';
import IE from '../../webAppComponents/DashboardIE/DashboardIE';

function DashboardPage() {
  const { authTokens } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };
  const chartData= {};

  const [ IEFilters, setIEFilters] = useState(null);
  const [ selectedIEFilters, setselectedIEFilters] = useState({
    "MC" : [],
    "SESSION" : []
  })
  const [ IEData, setIEData] = useState(null);

  const [ verticalFilters, setVerticalFilters] = useState([
    {"name": "Element", "content": [{"value": "general_bases_of_orientation", "label": "General Bases of Orientation"}, {"value": "game_actions", "label": "Game Actions"}, {"value": "principles_of_play", "label": "Principles of Play"}]},
    {"name": "Moment", "content": [{"value": "Offensive", "label": "Offensive"}, {"value": "Defensive", "label": "Defensive"}, {"value": "Mix", "label": "Mix"}, {"value": "Physical", "label": "Physical"}]}
  ]);
  const [ selectedVerticalFilters, setselectedVerticalFilters] = useState({
    "Element" : [],
    "Moment" : []
  })

  const [vertData, setVertData] = useState(null);
  const [verticalData, setVerticalData] = useState(
    []
  );

  useEffect(() => {
    fetchIEFiltersData();
    fetchVerticalFiltersData();
  }, []);

  useEffect(() => {
    if(selectedIEFilters.MC.length == 0 || selectedIEFilters.SESSION.length == 0) return;

    const queryString = `?MC=${selectedIEFilters.MC.join(',')}&S=${selectedIEFilters.SESSION.join(',')}`

    axios.get(process.env.REACT_APP_BACKEND + "training/dashboard-filtered-data" + queryString, {headers})
      .then((response) => {
        const futureIEData = response.data.reduce((array, data) => {
          const matchedElement = IEFilters[0].content.find(
            element => element.value === parseInt(data.microcycle)
          );

          if (matchedElement) {
            array.push({
              value: matchedElement.label,
              data: data.sessions_data
            });
          }

          return array;
        }, []);// empty array as initial value
        setIEData(futureIEData);
      })
      .catch((error) => {console.error(error)})

  }, [selectedIEFilters])

  useEffect(() => {
    if(selectedVerticalFilters.Element.length == 0 || selectedVerticalFilters.Moment.length == 0) return;

    let futureVerticalData = [];
    //console.log(vertData);
    //compare the selected filters with the data
    Object.keys(vertData).forEach((key) => {
      if(selectedVerticalFilters.Element.includes(key)){
        const data = vertData[key];
        //console.log(data);
        data.forEach((element) => {
          //console.log(element);
          if(selectedVerticalFilters.Moment.includes(element.category__name)){
            //console.log(element);
            futureVerticalData.push(element);
          }})
      }
    })

    //console.log(futureVerticalData);

    setVerticalData(futureVerticalData);

    //setVerticalData(futureVerticalData);
  }, [selectedVerticalFilters])

  const fetchVerticalFiltersData = () => {
    axios.get(process.env.REACT_APP_BACKEND + "training/exercise-options-usage", {headers})
      .then((response) => {
        setVertData(response.data);
      })
      .catch((error) => {console.error(error)})
  }

  const fetchIEFiltersData = () => {
    axios.get(process.env.REACT_APP_BACKEND + "training/dashboard-filter", { headers })
      .then((response) => {
        let microcylesFilter = [];
        let mostSessions = 0;
        response.data.forEach((element) => {
          if(element.microcycle_sessions > mostSessions)
            mostSessions = element.microcycle_sessions

          microcylesFilter.push({"value": element.microcyle_data.id,
            "label": 'MC' + element.microcyle_data.number
          })

        })
        const sessionsFilter = Array.from({ length: mostSessions }, (_, i) => ({
             value: i + 1,
             label: `S${i + 1}`
         }));
        setIEFilters([{"name": "MC", "content":microcylesFilter}, {"name": "SESSION", "content":sessionsFilter}])
      })
      .catch((error) => {console.error(error)})
  }

  const handleIEFilter = (filterName, value) => {
    let index = selectedIEFilters[filterName].findIndex((element) => element === value);

    if(index == -1){
      setselectedIEFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [...prevFilters[filterName], value] ,
      }));
    }else{
      setselectedIEFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedIEFilters[filterName].filter((element) => element!==value ),
      }));
    }
  };

  const handleVerticalFilter = (filterName, value) => {
    //there can only be one filter selected at a time
    setselectedVerticalFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: [value] ,
    }));
  
  }

  //console.log(IEData);


  return (
    <div className="db-page">
      <Sidebar selectedOption="dashboard" />
      <div className="db-content">
        <h4 className="db-title">Dashboard</h4>
        <div className='db-subtitle'>
          <hr className='db-subtitle-line'/>
          <div className='db-subtitle-content'>
            <img className="db-subtitle-image" src={IE_icon}/>
            <h5>ÍNDICE DE ESPECIFICIDADE</h5>
          </div>
          <hr className='db-subtitle-line db-subtitle-line2'/>
        </div>
        <div className='db-section'>
          <Vertical filters={verticalFilters} handle={handleVerticalFilter} data={verticalData} type={"radio"}/>
          <IE filters={IEFilters} handle={handleIEFilter} data={IEData} type={"checkbox"}/>
          {/*<Horizontal data={chartData} title="Data"/>
          <Horizontal data={chartData} title="Data2"/>
          <Horizontal data={chartData} title="Data3"/>
          <Horizontal data={chartData} title="Data4"/>*/}
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
