import { stages } from "konva/lib/Stage";
import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Line, Image, Transformer , Group, Circle, Rect, Arrow} from "react-konva";
import useImage from "use-image";
import { RiDeleteBinLine } from "react-icons/ri";


// Url image: vai buscar uma imagem, uma width e uma height  e cria e atualiza a imagem  <Image/>  é um componente do Konva
const URLImage = ({ image, stageWidth, stageHeight }) => {
	const [img] = useImage(image, "Anonymous");
	return <Image
			image={img}
			width={stageWidth}
			height={stageHeight}
			/>;
};

const CustomEraserCursor = ({ x, y, strokeSize}) => {
	return (
	<Circle
		x={x}
		y={y}
		radius={strokeSize} // Adjust the size of the circle
		fill="transparent"
		stroke="black"
		strokeWidth={1}
		listening={false} //	 Cursor should not interfere with other shapes
	/>
	);
};


//Stamp basicamente é um componente que recebe uma imagem (src), uma posição (x,y), o tamanho original do stage e o tamanho atual do stage
const Stamp = ({
		src,
		x,
		y,
		index,
		stamp,
		originalStageSize,
		currentStageSize,
		onChange,
		grabSelected,
		changeSelectedCanvasItem,
		isSelected,
		width,
		height,
		rotation,
		onDelete
	}) => {
    const [image] = useImage(src);

	const shapeRef = useRef();
	const trRef = useRef();
	const deleteButton = useRef();

	useEffect(() => {
		if (isSelected) {

		trRef.current.nodes([shapeRef.current]);
		trRef.current.getLayer().batchDraw();
		}
	}, [isSelected]);

	const onMouseEnter = (event) => {

		event.target.getStage().container().style.cursor = "pointer";

	};

	const onMouseLeave = (event) => {
		event.target.getStage().container().style.cursor = "default";
	};

    const scaleX = currentStageSize.width / originalStageSize.width;
    const scaleY = currentStageSize.height / originalStageSize.height;


	const handleDelete = () => {
		changeSelectedCanvasItem("",null);
		onDelete(shapeRef.current,index);
	};
    return (
		<React.Fragment>
			<Image
				z-index={100}
				image={image}
				scaleX={scaleX}
				scaleY={scaleY}
				x={x * scaleX}
				y={y * scaleY}
				draggable={grabSelected}
				ref={shapeRef}
				onMouseEnter={grabSelected ? onMouseEnter : null}
				onMouseLeave={grabSelected ? onMouseLeave : null}
				onTap={grabSelected ? () => changeSelectedCanvasItem("stamp",index) : null}
				width={width}
				height={height}
				rotation={rotation}
				onClick={(e) => {
					if (grabSelected && e.stopPropagation) {
						e.stopPropagation(); // Stop event propagation
					}
					if (grabSelected) {
						changeSelectedCanvasItem("stamp",index);
					}
				}}
				onDragEnd={(e) => {
					if (grabSelected) {
						onChange(index,{
							...stamp,
							x: e.target.x(),
							y: e.target.y()
						});
					}
				}}
				onTransformEnd={(e) => {
					if (grabSelected) {
						const node = shapeRef.current;
						const scaleX = node.scaleX();
						const scaleY = node.scaleY();
						node.scaleX(1);
						node.scaleY(1);
						onChange(index,{
							...stamp,
							x: node.x(),
							y: node.y(),
							width: Math.max(5, node.width() * scaleX),
							height: Math.max(node.height() * scaleY),
							rotation: node.rotation()
						});
					}
				}}
			/>
			{isSelected && grabSelected ? (

				<Transformer
					ref={trRef}
					onClick={(e) => {
						if (e && e.stopPropagation) {
						e.stopPropagation();
						}
					}}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
						return oldBox;
						}
						return newBox;
					}}
					rotateAnchorCursor= "grab"
				>
				<Group
					x={width ? width * scaleX : shapeRef.current.width() * scaleX}
					ref={deleteButton}
					onClick={handleDelete}
				>
				<Circle
					radius={8}
					fill="red"

				></Circle>
				<RiDeleteBinLine />

				</Group>
				</Transformer>

			) : null}
			</React.Fragment>

	);
}

const Rectangle = ({
	x,
	y,
	width,
	height,
	color,
	strokeSize,
	stageSize,
	grabSelected,
	rotation,
	onChange,
	index,
	isSelected,
	changeSelectedCanvasItem,
	rectangle,

	onDelete }) => {

	const shapeRef = useRef();
	const trRef = useRef();
	const deleteButton = useRef();

	useEffect(() => {
		if (isSelected) {

			trRef.current.nodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();

		}

	}, [isSelected]);

	const onMouseEnter = (event) => {

		event.target.getStage().container().style.cursor = "pointer";

	};

	const onMouseLeave = (event) => {
		event.target.getStage().container().style.cursor = "default";
	};

	const handleDelete = () => {
		changeSelectedCanvasItem("",null);
		onDelete(shapeRef.current);
	};


	return (
		<React.Fragment>
			<Rect
				x={x * stageSize.width}
				y={y * stageSize.height}
				width={width * stageSize.width}
				height={height * stageSize.height}
				stroke={color}
				onMouseEnter={grabSelected ? onMouseEnter : null}
				onMouseLeave={grabSelected ? onMouseLeave : null}
				strokeWidth={strokeSize}
				draggable={grabSelected}
				rotation={rotation}
				ref={shapeRef}
				onTap={grabSelected ? () => changeSelectedCanvasItem("line",index) : null}
				onClick={(e) => {
					if (grabSelected && e.stopPropagation) {
						e.stopPropagation(); // Stop event propagation
					}
					if (grabSelected) {
						changeSelectedCanvasItem("line",index);
					}
				}}
				onDragEnd={(e) => {
					if (grabSelected) {

						onChange(index,{
							x: e.target.x(),
							y: e.target.y(),
							width: e.target.width(),
							height: e.target.height(),
							rotation: e.target.rotation()
						}, stageSize);
					}

				}}
				onTransformEnd={(e) => {
					if (grabSelected) {
						const node = shapeRef.current;
						const scaleX = node.scaleX();
						const scaleY = node.scaleY();
						node.scaleX(1);
						node.scaleY(1);
						onChange(index,{
							...rectangle,
							x: node.x(),
							y: node.y(),
							width: node.width() * scaleX,
							height: node.height() * scaleY,
							rotation: node.rotation()
						}, stageSize);
					}
				}}
			/>
			{isSelected && grabSelected ? (
				<Transformer
					ref={trRef}
					onClick={(e) => {
						if (e && e.stopPropagation) {
						e.stopPropagation();
						}
					}}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}

				>
				<Circle
					radius={8}
					fill="red"
					ref={deleteButton}
					onClick={handleDelete}
					x={width * stageSize.width + 5}
				></Circle>
				</Transformer>
			) : null}
		</React.Fragment>
	);
};

const CustomArrow = ({
	line,
	color,
	strokeWidth,
	stageSize,
	index,
	onChange,
	isSelected,
	changeSelectedCanvasItem,
	rotation,
	grabSelected,

	onDelete }) => {

	const shapeRef = useRef();
	const trRef = useRef();
	const deleteButton = useRef();

	useEffect(() => {
		if (isSelected) {
			trRef.current.nodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();

		}

	}, [isSelected]);

	const onMouseEnter = (event) => {
		event.target.getStage().container().style.cursor = "pointer";
	};

	const onMouseLeave = (event) => {
		event.target.getStage().container().style.cursor = "default";

	};
	//useEffect to console.log the coords of my mouse when i click

	const handleDelete = () => {
		changeSelectedCanvasItem("",null);
		onDelete(shapeRef.current);
	};


	return (
		<React.Fragment>
			<Arrow
				x={line.info[0].startX * stageSize.width}
				y={line.info[0].startY * stageSize.height}
				points={[0, 0, line.info[0].width * stageSize.width, line.info[0].height * stageSize.height]}
				z-index={100}
				stroke={color}
				strokeWidth={strokeWidth}
				onMouseEnter={grabSelected ? onMouseEnter : null}
				onMouseLeave={grabSelected ? onMouseLeave : null}
				draggable={grabSelected}
				rotation={rotation}
				ref={shapeRef}
				onTap={grabSelected ? () => changeSelectedCanvasItem("line",index) : null}
				onClick={(e) => {
					if (grabSelected && e.stopPropagation) {
						e.stopPropagation(); // Stop event propagation
					}
					if (grabSelected) {
						changeSelectedCanvasItem("line",index);
					}
				}}
				onDragEnd={(e) => {

					if (grabSelected) {
						onChange(index, {
							startX: e.target.x() ,
							startY: e.target.y() ,
							width: e.target.width(),
							height: e.target.height(),
							rotation: e.target.rotation()
						}, stageSize);
					}
				}}
				onTransformEnd={(e) => {
					if (grabSelected) {
						const node = shapeRef.current;
						const scaleX = node.scaleX();
						const scaleY = node.scaleY();
						node.scaleX(1);
						node.scaleY(1);

						onChange(index,{
							...line,
							startX: node.x() ,
							startY: node.y(),
							width: node.width() * scaleX,
							height: node.height() * scaleY,
							rotation: node.rotation(),
						}, stageSize);
					}
				}}
			/>
			{isSelected && grabSelected ? (
				<Transformer
					ref={trRef}
					onClick={(e) => {
						if (e && e.stopPropagation) {
						e.stopPropagation();
						}
					}}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				>

				<Circle
					radius={8}
					fill="red"
					ref={deleteButton}
					onClick={handleDelete}
					x={line.info[0].width * stageSize.width + 15}
				/>


				</Transformer>
			) : null}
		</React.Fragment>
	);
};





//É aqui que se desenha
const Drawing = ({ penSelected, selectedStamp, stamps, setStamps, setCampoState, campoState, lines, setLines,justDisplay, currentColor, stageRef, stageId, squareSelected, arrowSelected, strokeSize, grabSelected, selectedCanvasItem, setSelectedCanvasItem, maintainAspectRatio}) => {
	const [stageSize, setStageSize] = useState();  // Tamanho do stage
	const isDrawing = useRef(false);  // track drawing state
	const isErasing = useRef(false); // track erasing state
	const [squarePoints, setSquarePoints] = useState([]); // track square points
	const [arrowPoints, setArrowPoints] = useState([]); // track arrow points
	 // track selected canvas item
	const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

	const onDeleteStamp = (node, index) => {
		const newStamps = [...stamps];
		newStamps.splice(index , 1);
		setStamps(newStamps);
	};

	const onDeleteLine = (node) => {
		const newLines = [...lines];
		newLines.splice(node.index -1, 1);
		setLines(newLines);
	};

	const changeSelectedCanvasItem = (type,index) => {
		setSelectedCanvasItem([type,index]);
	};

	const handleStampChange = (index,newAttrs) => {
		const shapes = stamps.slice();
		shapes[index] = newAttrs;
		 // Add this line for debug logging
		setStamps(shapes);
	};

	const handleRectangleChange = (index, newAttrs, stageSize) => {

		const rectangle = lines[index];

		const newEndX = newAttrs.x + newAttrs.width / stageSize.width;
		const newEndY = newAttrs.y + newAttrs.height / stageSize.height;

		// Update rectangle information
		const newRectangle = {
			...rectangle.info[0],
			startX: newAttrs.x / stageSize.width,
			startY: newAttrs.y / stageSize.height,
			endX: newEndX / stageSize.width,
			endY: newEndY / stageSize.height,
			width: Math.abs(newEndX - newAttrs.x  ),
			height: Math.abs(newEndY  - newAttrs.y  ),
			rotation: newAttrs.rotation || 0
		};

		const shapes = lines.slice();
		shapes[index].info[0] = newRectangle;
		setLines(shapes);
	}

	const handleArrowChange = (index, newAttrs, stageSize) => {
		const arrow = lines[index];

		let newWidth = newAttrs.width - 10;
		let newHeight = newAttrs.height - 10;

		if (arrow.info[0].width < 0) {
			newWidth = -newWidth;
		}
		if (arrow.info[0].height < 0) {
			newHeight = -newHeight;
		}


		const newEndX = newAttrs.startX + newWidth / stageSize.width;
		const newEndY = newAttrs.startY + newHeight / stageSize.height;

		const newArrow = {
			...arrow.info[0],
			startX: newAttrs.startX / stageSize.width,
			startY: newAttrs.startY / stageSize.height,
			rotation: newAttrs.rotation || 0,
			width: newEndX - newAttrs.startX,
			height: newEndY - newAttrs.startY,
		};

		const shapes = lines.slice();
		shapes[index].info[0] = newArrow;
		setLines(shapes);
	};


	const renderInitialStamps = () => {
		//desenhar os stamps iniciais, vai buscar o x e y e desenha
		if (stamps.length > 0) {
			return stamps.map((stamp, i) => (
				<Stamp
					key={i}
					index={i}
					src={stamp.src}
					x={stamp.x}
					y={stamp.y}
					stamp={stamp}
					originalStageSize={stamp.originalStageSize}
					currentStageSize={stageSize}
					onChange={handleStampChange}
					grabSelected={grabSelected}
					changeSelectedCanvasItem={changeSelectedCanvasItem}
					isSelected={selectedCanvasItem &&
								selectedCanvasItem[0] === "stamp" &&
								selectedCanvasItem[1] === i}
					width={stamp?.width} // Pass width
					height={stamp?.height} // Pass height
					rotation={stamp?.rotation}

					onDelete={onDeleteStamp}
				/>
			));
		}
		return null;
	};

	const renderInitialLines = () => {


		//desenhar linhas iniciais ( ex: Abrir um desenho ja feito)
		if (lines.length > 0) {
			return lines.map((line, i) => {

				//Se a tool for um quadrado entao desenha um quadrado 2 pontos e fazem a diagonal pricnipal
				if (line.tool === "square") {
					const squareX= Math.min(line.info[0].startX, line.info[0].endX);
					const squareY= Math.min(line.info[0].startY, line.info[0].endY);
					(line.info[0])
					// Call Rectangle function directly:
					return <Rectangle
						key={i}
						x={squareX}
						y={squareY}
						width={line.info[0].width}
						height={line.info[0].height}
						color={line.color}
						strokeSize={line.strokeSize}
						stageSize={stageSize}
						grabSelected={grabSelected}
						rotation={line.info[0].rotation || 0}
						onChange={handleRectangleChange}
						index={i}
						isSelected={selectedCanvasItem &&
									selectedCanvasItem[0] === "line" &&
									selectedCanvasItem[1] === i}
						changeSelectedCanvasItem={changeSelectedCanvasItem}
						rectangle={line} // Pass entire line object

						onDelete={onDeleteLine}
					/>;

				}
				else if (line.tool === "arrow") {

					return <CustomArrow
							key={i}
							line={line}
							startX={line.info[0].startX}
							startY={line.info[0].startY}
							width= {line.info[0].width}
							height= {line.info[0].height}
							color={line.color}
							strokeWidth={line.strokeSize}
							stageSize={stageSize}
							index={i}
							onChange={handleArrowChange}
							isSelected={selectedCanvasItem &&
										selectedCanvasItem[0] === "line" &&
										selectedCanvasItem[1] === i}
							changeSelectedCanvasItem={changeSelectedCanvasItem}
							rotation={line.info[0].rotation || 0}
							grabSelected={grabSelected}
							onDelete={onDeleteLine}
							/>
				}
				return (

					//Linha percorre os pontos e desenha os
					<Line
						key={i}
						points={line.points ? line.points.flatMap((point) => [point.x * stageSize.width, point.y * stageSize.height]) : []}
						stroke={line.color}
						strokeWidth={line.strokeSize}
						tension={0.5}
						lineCap="round"
						globalCompositeOperation={line.tool === "eraser" ? "destination-out" : "source-over"}
					/>
				);
			});
		}
		return null;
	};

	   //array com as linhas do canvas

	//ISTO BASICAMENTE E PARA FAZER O DEBOUNCE DO RESIZE DO STAGE
	const debounce = (func, delay) => {
		let timeoutId;
		return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => func(...args), delay);
		};
	};

	useEffect(() => {

        const observeSizeWithDebounce = (targetId, callback, debounceDelay) => {
            const target = document.getElementById(targetId);

            if (target) {
                const resizeObserver = new ResizeObserver(debounce(callback, debounceDelay));
                resizeObserver.observe(target);

                return () => {
                    resizeObserver.disconnect();
                };
            }

            return () => {};
        };

        const checkScale = (widthAvailable, heightAvailable, scale) => {
          return widthAvailable * scale <= heightAvailable
        }

        const checkSizeStage = () => {
            const stageElement = document.getElementById(stageId);
            if (stageElement) {
                var width;
                var height;

                if(maintainAspectRatio === false){
                  height = stageElement.clientHeight;
                  width = stageElement.clientWidth;
                  setStageSize({ height, width});
                  return;
                }
                const heightAvailable = stageElement.clientHeight;
                const widthAvailable = stageElement.clientWidth;
                if(checkScale(widthAvailable, heightAvailable, 0.59)){
                  width = stageElement.clientWidth;
                  height = width * 0.59;
                }else{
                  height = stageElement.clientHeight;
                  width = height /0.59;
                }

                setStageSize({ height, width});
            }
        };

        // Observe changes to the specific stage with debounce
        const cleanupStage = observeSizeWithDebounce(stageId, checkSizeStage, 20);

        // Cleanup function
        return () => {
            cleanupStage();
        };
    }, [justDisplay, stageId])



    // Tratar de quando é clicado em cima do stage
	const handleMouseDown = (e) => {
		if (grabSelected) return;
		if (selectedStamp) {

            //se for um stamp entao mete o target : Imagem
			const pos = e.target.getStage().getPointerPosition();
            //aumenta o array com os stamps todos e com as suas posicoes
			setStamps([
				...stamps,
				{
					src: selectedStamp,
					x: pos.x -15,
					y: pos.y - 10,
					originalStageSize: { width: stageSize.width, height: stageSize.height }, // Store original stage size
					rotation: 0,
					// No need to set a width because we want its original size
				},
			]);
		} else if (squareSelected) {
			//se for um quadrado entao mete o target : Quadrado
			//se for o primeiro ponto entao mete o ponto no array
			//se for o segundo ponto entao mete o array com os 2 pontos e desenha o quadrado
			//Guarda os pontos do quadrado
            const pos = e.target.getStage().getPointerPosition();
			if (squarePoints?.length === 2) {
				const [startPoint, endPoint] = squarePoints;
				setLines([...lines, { tool: "square", info: [{
					startX: startPoint.x,
					startY: startPoint.y,
					endX: endPoint.x,
					endY: endPoint.y,
					width: Math.abs(endPoint.x - startPoint.x),
					height: Math.abs(endPoint.y - startPoint.y) ,

				}] ,strokeSize: strokeSize, color: currentColor }]);
				setSquarePoints([]);
			}
            setSquarePoints([...squarePoints, { x: pos.x / stageSize.width, y: pos.y / stageSize.height }]);
			//Se for arrow entao faz o mesmo mas para os 2 pontos da arrow
		} else if (arrowSelected) {
			const pos = e.target.getStage().getPointerPosition();

			if (arrowPoints?.length === 2) {
				const [startPoint, endPoint] = arrowPoints;
				(arrowPoints)

				const width = Math.abs(endPoint.x - startPoint.x);
				const height = Math.abs(endPoint.y - startPoint.y);
				let rotation = 0;

				setLines([...lines, { tool: "arrow", info: [{
					startX: startPoint.x,
					startY: startPoint.y,
					width: width,
					height: height,
					rotation: rotation,


				}] , strokeSize: strokeSize,color: currentColor }]);
				setArrowPoints([]);

			}
        setArrowPoints([...arrowPoints, { x: pos.x / stageSize.width, y: pos.y / stageSize.height }]);


		}

		else {
			isDrawing.current = penSelected === true;
			isErasing.current = penSelected === false;

            // se estiver em eraser mode entao apaga
			if (isErasing.current) {


				erasePoints(e);
			} else if (isDrawing.current) {
				const pos = e.target.getStage().getPointerPosition();
				setLines([...lines, { tool: "pen", points: [{ x: pos.x / stageSize.width, y: pos.y / stageSize.height }], strokeSize: strokeSize, color: currentColor }]);
			}
		}
	};

	const handleMouseMove = (e) => {
		if (grabSelected) return;
		const stage = e.target.getStage();
		const pos = stage.getPointerPosition();
		setCursorPos({ x: pos.x, y: pos.y});

		if (isErasing.current) {
			erasePoints(e);

		}
		else if (squareSelected && squarePoints?.length === 1) {

        }
		else if (isDrawing.current) {
			const stage = e.target.getStage();  // obter o stage
			const point = stage.getPointerPosition();  // obter a posicao do rato
			let lastLine = lines[lines?.length - 1];  // obter a ultima linha
            // adicionar a posicao do rato a ultima linha
			lastLine.points = lastLine.points.concat({ x: point.x / stageSize.width, y: point.y / stageSize.height });
            // substituir a ultima linha pela linha com a nova posicao
			lines.splice(lines?.length - 1, 1, lastLine);
            // atualizar o array com as linhas
			setLines(lines.concat());
		}
	};

    //quando se larga o rato
	const handleMouseUp = () => {

		if (squarePoints?.length === 2) {
			const [startPoint, endPoint] = squarePoints;

			setLines([...lines, { tool: "square", info: [{
				startX: startPoint.x,
				startY: startPoint.y,
				endX: endPoint.x,
				endY: endPoint.y,

				width: Math.abs(endPoint.x - startPoint.x),
				height: Math.abs(endPoint.y - startPoint.y) ,

			}] ,strokeSize: strokeSize, color: currentColor }]);
			setSquarePoints([]);
		}

		if (arrowPoints?.length === 2) {
			const [startPoint, endPoint] = arrowPoints;

			const width = endPoint.x - startPoint.x;
			const height = endPoint.y - startPoint.y;

			let rotation = 0;

			rotation = 0;

			setLines([...lines, { tool: "arrow", info: [{
				startX: startPoint.x,
				startY: startPoint.y,
				width: width,
				height:height,
				rotation: rotation,
			}] ,strokeSize: strokeSize, color: currentColor }]);
			setArrowPoints([]);

		}

		isDrawing.current = false;
		isErasing.current = false;
	};

    //apaga os pontos que estao dentro do raio de 10px
	const erasePoints = (e) => {
		const stage = e.target.getStage();
		const point = stage.getPointerPosition();
		const eraseRadiusSquared = strokeSize * strokeSize; // Adjust the radius as needed

		const newLines = [];

		lines.forEach((line) => {

			if (line.tool === "square" || line.tool === "arrow") {

				let currentLine = { tool: line.tool, info: [], color: line.color, strokeSize:line.strokeSize, erase: false };

				line.info.forEach((p, index) => {
					const dx = p.startX * stageSize.width - point.x;
					const dy = p.startY * stageSize.height - point.y;
					const distanceSquared = dx * dx + dy * dy;

					const dx2 = p.endX * stageSize.width - point.x;
					const dy2 = p.endY * stageSize.height - point.y;
					const distanceSquared2 = dx2 * dx2 + dy2 * dy2;

					if (distanceSquared < eraseRadiusSquared || distanceSquared2 < eraseRadiusSquared) {
						currentLine.erase = true;
					} else {
						currentLine.info.push(p);
					}

					if (index === line.info?.length - 1 && currentLine.erase) {
						// Do nothing, skip the line
					} else if (index === line.info?.length - 1) {
						newLines.push(currentLine);
					}
				});
			} else {

					let currentLine = {
						tool: line.tool,
						points: [],
						color: line.color,
						strokeSize: line.strokeSize,
						erase: false
					};

					line.points.forEach((p, index) => {
						const dx = p.x * stageSize.width - point.x;
						const dy = p.y * stageSize.height - point.y;
						const distanceSquared = dx * dx + dy * dy;

						if (distanceSquared > eraseRadiusSquared) {
							// Point is not erased, add it to the current line
							currentLine.points.push(p);
						} else {
							// Point is erased, create a new line segment if current line is not empty
							if (currentLine.points.length > 0) {
								newLines.push({ ...currentLine }); // Add a copy of the currentLine
							}
							// Reset currentLine to start fresh for the next segment
							currentLine = {
								tool: line.tool,
								points: [],
								color: line.color,
								strokeSize: line.strokeSize,
								erase: true
							};
						}
					});

					// Add the last modified line (if any) after erasing
					if (currentLine.points.length > 0) {
						newLines.push(currentLine);
					}
			}
		});

		// Erase stamps e atualizar o array stamps
		const newStamps = stamps.filter((stamp) => {
			const dx = stamp.x - point.x;
			const dy = stamp.y - point.y;
			const distanceSquared = dx * dx + dy * dy;
			return distanceSquared > eraseRadiusSquared;
		});

		setLines(newLines);
		setStamps(newStamps);
	};

    if (!stageSize) {
        return null;
    }
	else if (justDisplay === true) {

		return (
            <Stage width={stageSize.width} height={stageSize.height} scale={1} >
                <Layer>
                    <URLImage image={campoState} stageWidth={stageSize.width} stageHeight={stageSize.height} />
                    {renderInitialLines()}
					{renderInitialStamps()}


                </Layer>
            </Stage>
        );
	}
    else {
	return (
		<Stage width={stageSize.width} height={stageSize.height} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} ref={stageRef}>
			<Layer>
				<URLImage image={campoState} stageWidth={stageSize.width} stageHeight={stageSize.height} />
				{renderInitialLines()}
				{renderInitialStamps()}


				{lines
					.filter((line) => line.tool !== "square" && line.tool !== "arrow")
					.map((line, i) => (
						<Line
							key={i}
							points={line.points ? line.points.flatMap((point) => [point.x * stageSize.width, point.y * stageSize.height]) : []}
							stroke={line.color}
							strokeWidth={line.strokeSize}
							tension={0.5}
							lineCap="round"
							globalCompositeOperation={line.tool === "eraser" ? "destination-out" : "source-over"}
						/>
					))}
				{isErasing.current && <CustomEraserCursor x={cursorPos.x} y={cursorPos.y} strokeSize={strokeSize}/>}
			</Layer>
		</Stage>

        );
    }
};
export default Drawing;
