import React, { useEffect } from 'react';
import './FormsRow.css';
import { useNavigate } from 'react-router-dom';
import concluded from '../../webAppAssets/concluded.svg';
import ongoing from '../../webAppAssets/ongoing.svg';
import answered from '../../assets/answered.svg';
import notAnswered from '../../assets/notAnswered.svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


function FormsRow({ session, setSelected, size }) {
    const navigate = useNavigate();
    const { t } = useTranslation(['player-forms']);


    const handleButtonClick = () => {
        const { post_gym, post_train, pre_train } = session;
        const url = `/webapp/player/QuestionsPlayerConfirmation?ucgym=${post_gym.id}&ucgym_ans=${post_gym.answered}&uctrain=${post_train.id}&uctrain_ans=${post_train.answered}&ucpre=${pre_train.id}&ucpre_ans=${pre_train.answered}`;

        // Use navigate to go to the new route with the parameters
        navigate(url);
    };

    const isAnyAnsweredFalse = !session.pre_train.answered || !session.post_gym.answered || !session.post_train.answered;

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleArrows = (e) => {
        if (e.target.value === "0" && selected > 0) {
            setSelected((prev) => prev - 1);
        } else if (e.target.value === "1" && selected < size - 1) {
            setSelected((prev) => prev + 1);
        }
    }


    return (
        <div className="webapp-forms-page-player-row">
            {isMobile &&
                <p className='webapp-forms-arrows webapp-forms-arrow-left' value="0" onClick={handleArrows}>&lt;</p>
            }
            {isMobile &&
                <p className='webapp-forms-arrows webapp-forms-arrow-right' value="1" onClick={handleArrows}>&gt;</p>
            }
            <div className="webapp-forms-page-player-row-info webapp-forms-page-player-row-info-1">
                {session.session}
            </div>

            <div className="webapp-forms-page-player-row-info webapp-forms-page-player-row-info-2">
                {session.microcycle}
            </div>

            <div className="webapp-forms-page-player-row-info webapp-forms-page-player-row-info-final">
                {session.date}
                {session.status === "Completed" ? (
                    <img src={concluded} className='webapp-forms-page-player-img-status' alt="Concluded" />
                ) : (
                    <img src={ongoing} className='webapp-forms-page-player-img-status' alt="Ongoing" />
                )}
            </div>

            <div className="webapp-forms-page-player-row-info webapp-forms-page-player-row-info-training">
                {session.training_plan}
            </div>


            <div className="webapp-forms-page-player-row-info webapp-forms-page-player-row-info-answers">
                <div classname="webapp-forms-page-player-answers-column">
                    <div className="webapp-forms-page-player-answers-row">
                        <img src={session.pre_train.answered ? answered : notAnswered} alt="Pre-Training" />
                        <div className={session.pre_train.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                            {t('Pre-Training')}
                        </div>
                    </div>
                    <div className="webapp-forms-page-player-answers-row">
                        <img src={session.post_gym.answered ? answered : notAnswered} alt="Pre-Training" />
                        <div className={session.post_gym.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                            {t('Post-Gym')}
                        </div>
                    </div>
                    <div className="webapp-forms-page-player-answers-row">
                        <img src={session.post_train.answered ? answered : notAnswered} alt="Pre-Training" />
                        <div className={session.post_train.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                            {t('Post-Training')}
                        </div>
                    </div>
                </div>
                {(isAnyAnsweredFalse) ? (
                    (!isMobile) && (
                        <button onClick={handleButtonClick}>
                            {t('Answer')}
                        </button>
                    )
                ) : (
                    <span className="webapp-forms-page-player-row-space-filler-button"></span>
                )}
            </div>
        </div>
    );
}

export default FormsRow;
