import react, { useState } from 'react';
import "./ImportItemsPopup.css";
import selectedCheck from "../../webAppAssets/selected-check-icon.svg";
import { Exercise, Folder } from '../TrainingExerciseFolders/TrainingExerciseFolders';


const ImportItemsPopup = (props) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleSelectedItemChange = (item) => {
        setSelectedItem(item);

    }
    return (
        <div className="webapp-import-items-popup">
            <div className="webapp-import-items-popup-inner">
                <div className="webapp-import-items-popup-container">
                    <p className="black-18px-400">Por favor, selecione <span className="black-18px-800">o exercício</span> que pretende importar</p>
                    <span className="webapp-import-items-popup-division"></span>
                    <div className="webapp-import-items-popup-main-div">
                        <h2 >Exercícios</h2>
                        <div className="webapp-import-items-popup-grid">
                            {props.itemsList?.map((item) => (
                                <div className={`webapp-import-items-popup-grid-item ${selectedItem === item.id ? 'webapp-import-items-selected-item' : ''}`}>
                                <Exercise name={item.name} handleSelectedItemChange={handleSelectedItemChange} item={item.id} selectedItem={selectedItem} exercise={item}/>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="webapp-import-items-popup-buttons">
                        <button className="webapp-import-items-popup-cancel-button" onClick={props.closePopup}>Cancelar</button>
                        <button className="webapp-import-items-popup-import-button" onClick={() => props.importItem(selectedItem)}>Importar</button>
                    </div>
                </div>
            </div>
        </div>


    )
}


export default ImportItemsPopup;
