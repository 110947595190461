import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";

const CoachRoute = () => {
    const { authTokens, logoutUser, coachTeam } = useContext(AuthContext);
    if (authTokens) {
      // If the user is logged, verify if the user is a coach
      const role = jwtDecode(authTokens.access).role;
      if (role === "COACH") {
        if(!coachTeam && window.location.pathname != '/webapp/createteam'){ 
          return <Navigate to='/webapp/createteam'/>;
        } 
          return <Outlet />;
      } else if (role === "PLAYER") {
        return <Navigate to="webapp/player/forms" />;
      }
      else {
        // If the user is logged but the role is not defined, redirect to the login page
        // Remove user from local storage, to avoid errors
        logoutUser(); // has navigate("/webapp")
      }
    } else {
        return <Navigate to="/webapp/login" />;
    }
};

export default CoachRoute;
