import "./DashboardIE.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DashboardFilters from "../DashboardFilters/DashboardFilters";

import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Tooltip, LineController, BarController} from "chart.js";
import { Chart } from "react-chartjs-2";
import { use } from "i18next";


ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, LineController, BarController);

const IE = ({ data, filters, handle, type }) => {
  const { t } = useTranslation(["injuries-page"]);

  const [labels, setLabels] = useState([]);
  const [lineData, setLineData] = useState(null);
  const [barData, setBarData] = useState(null);

  const [lineDataset, setLineDataset] = useState([]);
  const [barDataset, setBarDataset] = useState([]);

  useEffect(() => {
    let label = [];
    let lines = [];
    let bars = [];

    data?.map((element) => {
        let _labels = [];
        let _lines = [];
        let _bars = [];
        element.data.map((data, index) => {
            _labels.push("S"+ (index+1) + "/" +element.value);
            _lines.push(data.ie_total);
            _bars.push(data.time_density);
        });
        label.push(_labels);
        lines.push(_lines);
        bars.push(_bars);
    })

    if(lines.length === 0) return;
    
    lines = lines[0].map((_, colIndex) => lines.map(row => row[colIndex]));
    lines = lines.reduce((acc, val) => acc.concat(val), []);
    bars = bars[0].map((_, colIndex) => bars.map(row => row[colIndex]));
    bars = bars.reduce((acc, val) => acc.concat(val), []);
    label = label[0].map((_, colIndex) => label.map(row => row[colIndex]));
    label = label.reduce((acc, val) => acc.concat(val), []);

    setLabels(label);
    setLineData(lines);
    setBarData(bars);
  }, [data]);

    const Data = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: t("E.I."),
          borderColor: 'rgb(252,165,85)',
          backgroundColor: 'rgb(252,165,85)',
          borderWidth: 5,
          pointRadius: 6,
          data: lineData,
          yAxisID: 'y1',
        },
        {
          type: 'bar',
          label: t("Time Density"),
          backgroundColor: 'rgb(60,80,252)',
          data: barData,
          borderWidth: false,
          borderRadius: 10,
          yAxisID: 'y',
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
    };

    return (
        <div className="webapp-vertical">
         <div className="webapp-vertical-filters">
            {filters?.map((filter) => {
              return(
                <DashboardFilters name={filter.name} filters={filter.content} handle={handle} type={type}/>
              )
            })}
             </div>
            <div className="webapp-vertical-content">
                <div className="webapp-vertical-header">
                    <h2 className="webapp-vertical-header-title">{t("Relation E.I. - Time Density")}</h2>
                </div>
                <div className="webapp-vertical-chart">
                    <Chart data={Data} options={options}/>
                </div>
            </div>
        </div>
    );
}

export default IE;
