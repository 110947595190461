import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

const ConfirmPasswordPopup = ({setState, action}) => {
    const { t } = useTranslation(['coach-profile']);

    const [password, setPassword] = useState("")

    const { authTokens, logoutUser } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const onCancel = () => {
        setState(false)
    }

    const checkPassword = (password) => {
        try {
            axios.post(process.env.REACT_APP_BACKEND + 'users/password-verification', { password }, { headers })
                .then((response) => {
                    // Handle the successful response here
                    action()
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="webapp-popup-background">
            <div className="webapp-popup">
                <div className="webapp-popup-warning">
                    <h3 className="webapp-popup-title-normal">{t('Confirm your')} {t('password')}</h3>
                </div>
                <div className="webapp-popup-message">
                    <p>{t('To continue cancelling your subscription, please insert you password')}</p>
                </div>
                <input type="password" className="webapp-coachprofile-password-body-input" onChange={(e) => setPassword(e.target.value)}/>
                <hr className="webapp-popup-line"/>
                <div className="webapp-popup-buttons">
                    <button onClick={onCancel} className="webapp-createteam-button webapp-createteam-white-button">{t('Cancel')}</button>
                    <button onClick={()=>checkPassword(password)} className="webapp-createteam-button webapp-createteam-blue-button">{t('Confirm')}</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPasswordPopup;