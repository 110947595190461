import "./DashboardVertical.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DashboardFilters from "../DashboardFilters/DashboardFilters";

const Vertical = ({ data, filters, handle, type }) => {
    const { t } = useTranslation(["injuries-page"]);
    const Data = [
        {
            id: 0,
            name: 'Injury 1',
            percentage: 50,
        },
        {
            id: 1,
            name: 'Injury 2',
            percentage: 100,
        },
        {
            id: 2,
            name: 'Injury 3',
            percentage: 30,
        }
    ];

    useEffect(() => {
        //select all the bars
        const bars = document.querySelectorAll(".webapp-vertical-bar");
        //give each bar a height based on the percentage
        //give the height in percentage
        bars.forEach((bar, index) => {
            bar.style.height = `${data[index].usage_count}rem`;
        });
    }, [data]);

    return (
        <div className="webapp-vertical">
         <div className="webapp-vertical-filters">
            {filters?.map((filter) => {
              return(
                <DashboardFilters name={filter.name} filters={filter.content} handle={handle} type={type}/>
              )
            })}
             </div>
            <div className="webapp-vertical-content">
                <div className="webapp-vertical-header">
                    <h2 className="webapp-vertical-header-title">{t("Number of Exercises")}</h2>
                </div>
                <table className="webapp-vertical-table">
                    {/*<thead className='webapp-injurieschart-table-header'>
                        <tr>
                            <th></th>
                            <th className='webapp-injurieschart-table-header-element'>{t('Percentage')}</th>
                        </tr>
                    </thead>*/}
                    <tbody className="webapp-vertical-table-body">
                        <tr className="webapp-vertical-table-body-bars">
                            {<div className='webapp-vertical-scale'>
                                <p>0%</p>
                                <p>25%</p>
                                <p>50%</p>
                                <p>75%</p>
                                <p>100%</p>
                            </div>}
                            {Object.keys(data).map((item, index) => {
                                const itemData = data[item];
                                return (
                                    <td className="webapp-vertical-table-body-element">
                                        <div className='webapp-vertical-bar-lines'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className={`webapp-vertical-bar webapp-vertical-bar-${itemData.name}`}>

                                            <div>{itemData.usage_count}</div>
                                            <span className='webapp-vertical-bar-tooltip'>{itemData.usage_count}</span>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td></td>
                            {Object.keys(data).map((item, index) => {
                                const itemData = data[item];
                                return (
                                    <td className="webapp-vertical-table-body-labels">
                                        <p>{t(itemData.name)}</p>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Vertical;
