import react, { useEffect, useState, useContext, useRef, version } from "react";
import "./CreateTrainingItems.css";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import MultipleChoiceDropdown from "../MultipleChoiceDropdown/MultipleChoiceDropdown";
import Axios from "axios";
import { FiClock } from "react-icons/fi";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { Doughnut } from 'react-chartjs-2';
import DoghnutGraph from '../DoughnutGraph/DoughnutGraph.jsx';


const ExerciseElements = (props) => {
  const { t } = useTranslation(["create-training-page"]);
  const [dropdownItems, setDropdownItems] = useState(props.dropdownItems);

  const [openDropdown, setOpenDropdown] = useState(null);

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const handleGeneralObjectiveDropdown = () => {
    setOpenDropdown(
      openDropdown === "general_bases_of_orientation"
        ? null
        : "general_bases_of_orientation",
    );
  };

  const handleGamePrinciplesDropdown = () => {
    setOpenDropdown(
      openDropdown === "principles_of_play" ? null : "principles_of_play",
    );
  };

  const handleSpecialReferenceDropdown = () => {
    setOpenDropdown(openDropdown === "game_actions" ? null : "game_actions");
  };

  return (
    <div className="create-trainings-exercise-elements-forms-1">
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {t("General Bases Of Orientation")}
          <span className="webapp-asterisco">*</span>
        </label>
        <div>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleGeneralObjectiveDropdown}
          >
            <p>
              {props.currentExercise.general_bases_of_orientation === null
                ? t("General Bases Of Orientation")
                : t(props.currentExercise.general_bases_of_orientation.name)}
            </p>
            {openDropdown !== "general_bases_of_orientation" ? (
              <div onClick={(e) => {e.stopPropagation(); handleGeneralObjectiveDropdown()} }>
                <BiSolidUpArrow />
              </div>
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "general_bases_of_orientation" && (
            <MultipleChoiceDropdown
              dropdownOptions={dropdownItems.general_bases_of_orientation}
              currentExercise={props.currentExercise}
              valueToChange="general_bases_of_orientation"
              handleExerciseDataChange={props.handleExerciseDataChange}
              label="General Bases"
              typeOfKey="foreignKey"
              t={t}
            />
          )}
        </div>
      </div>
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {t("Game Actions")}
          <span className="webapp-asterisco">*</span>
        </label>
        <div>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleSpecialReferenceDropdown}
          >
            <p>
              {props.currentExercise.game_actions === null
                ? "Game Actions"
                : `${props.currentExercise.game_actions.name}`}
            </p>

            {openDropdown !== "game_actions" ? (
              <BiSolidDownArrow />
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "game_actions" && (
            <MultipleChoiceDropdown
              dropdownOptions={dropdownItems.game_actions}
              valueToChange="game_actions"
              currentExercise={props.currentExercise}
              handleExerciseDataChange={props.handleExerciseDataChange}
              label="Referencia Especial"
              typeOfKey="foreignKey"
              t={t}
            />
          )}
        </div>
      </div>
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {t("Principles Of Play")} <span className="webapp-asterisco">*</span>
        </label>
        <div>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleGamePrinciplesDropdown}
          >
            <p>
              {props.currentExercise.principles_of_play.length === 0
                ? t("Principles Of Play")
                : props.currentExercise.principles_of_play
                    .map((principle) => principle.name)
                    .join(", ")}
            </p>
            {openDropdown !== "principles_of_play" ? (
              <BiSolidDownArrow />
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "principles_of_play" && (
            <MultipleChoiceDropdown
              dropdownOptions={dropdownItems.principles_of_play}
              currentExercise={props.currentExercise}
              valueToChange="principles_of_play"
              handleExerciseDataChange={props.handleExerciseDataChange}
              label="Principios de jogo"
              typeOfKey="foreignKeyList"
              t={t}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ExerciseDensityElements = (props) => {

  const toStringFormat = (time) => {
    if (typeof time === "string")
      return time

    // from seconds to hh:mm:ss
    let hours = Math.floor(time / 3600);
    if (hours / 10 < 1)
      hours = `0${hours}`

    let minutes = Math.floor((time - (hours * 3600)) / 60);
    if (minutes / 10 < 1)
      minutes = `0${minutes}`

    let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
    if (seconds / 10 < 1)
      seconds = `0${seconds}`

    return (`${hours}:${minutes}:${seconds}`);
  }

  const fromStringToSeconds = (time_string) => {
    if (typeof time_string !== "string") return time_string

    const [hours, minutes, seconds] = time_string.split(':').map(Number);
    const time_seconds = hours * 3600 + minutes * 60 + seconds;

    return time_seconds
  }

  const totalTimeSeconds = (rep, rest, num_rep) => {
    return  ( num_rep * fromStringToSeconds(rep)) + (num_rep * fromStringToSeconds(rest));
  }

  return (
    <div className={`create-trainings-exercise-elements-forms-2 ${props.vertical == true ?  'vertical' : null}`}>
      <div className="create-trainings-exercise-density-elements-dropdowns">
        <div>
          <label className="black-16px-700">
            {props.t("Nº of repetitions")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <input
            type="number"
            placeholder={
              props.currentExercise.num_reps === null
                ? "Nº "
                : `${props.currentExercise.num_reps}`
            }
            min={0}
            value={props.currentExercise.num_reps} // Bind the input value to the state
            onChange={(e) =>
              props.handleExerciseDataChange(
                "num_reps",
                parseInt(e.target.value) || 0,
              )
            } // Update numReps on input change
          />
        </div>
        <div className="create-trainings-exercise-density-elements-time-input">
          <label className="black-16px-700">
            {props.t("Repetition duration")}
            <span className="webapp-asterisco">*</span>
          </label>
          <span>
            <input
              type="text"
              placeholder={
                props.currentExercise.rep_duration === null
                  ? "00:00:00"
                  : `${toStringFormat(props.currentExercise.rep_duration)}`
              }
              value={toStringFormat(props.currentExercise.rep_duration)}
              onChange={(e) => props.handleTimeInputChange(false, e)}
            />
            <span> <FiClock /> </span>
          </span>
        </div>
        <div className="create-trainings-exercise-density-elements-time-input">
          <label className="black-16px-700">
            {props.t("Pause duration")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <span>
            <input
              type="text"
              placeholder={
                props.currentExercise.rest_time === null
                  ? "00:00:00"
                  : `${toStringFormat(props.currentExercise.rest_time)}`
              }
              value={toStringFormat(props.currentExercise.rest_time)}
              onChange={(e) => props.handleTimeInputChange(true, e)}
            />
            <span> <FiClock /> </span>
          </span>
        </div>
      </div>

      <div className="create-trainings-exercise-density-elements-results">
        <h3 className="black-16px-700">{props.t("Result Elements")}</h3>
        <div className="create-trainings-exercise-density-elements-results-container">
          <div>
            <p className="dark-blue-16px-700 ">
              {props.t("Total duration of the exercise")}{" "}
            </p>
            <p className="dark-blue-16px-700">
              <span>
                <FiClock />
              </span>{" "}
              {toStringFormat(totalTimeSeconds(props.currentExercise.rep_duration, props.currentExercise.rest_time, props.currentExercise.num_reps))}{" "}
              min
            </p>
          </div>
          <DoghnutGraph title={[props.t("Density of"), props.t("work time")]} titleAlign={"start"} titleFont={16} labels={[props.t("active"), props.t("rest")]}
           data={[props.timeDensity, 100 - props.timeDensity]} centerText={'%'} darkMode={false} />
        </div>
      </div>
    </div>
  );
};

const InternalLogicElements = (props) => {
  const [dropdownItems, setDropdownItems] = useState(props.dropdownItems);

  const dropdownRefs = {
    dropdown1: useRef(null),
    dropdown2: useRef(null),
    dropdown3: useRef(null),
    dropdown4: useRef(null),
    dropdown5: useRef(null),
    dropdown6: useRef(null),
    dropdown7: useRef(null),
    dropdown8: useRef(null),
    dropdown9: useRef(null),
  };

  const [dropdownStates, setDropdownStates] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
    dropdown5: false,
    dropdown6: false,
    dropdown7: false,
    dropdown8: false,
    dropdown9: false,
  });

  const [currentDropdownNames, setCurrentDropdownNames] = useState({
    dropdown1: "Handball",
    dropdown2: "8 to 10 players",
    dropdown3: "An guidance",
    dropdown4: "Common and segmented space",
    dropdown5: "Equality + Joker(s) mixed",
    dropdown6: "Without goalkeeper",
    dropdown7: "Inter-Sectorial (Med-Ofe)",
    dropdown8: "≤ 60 m2 x player",
    dropdown9: "P. and E. with OT",
  });

  const toggleDropdown = (dropdownName) => {
    setDropdownStates((prevStates) => ({
      ...Object.fromEntries(
        Object.keys(prevStates).map((key) => [
          key,
          key === dropdownName ? !prevStates[key] : false,
        ]),
      ),
    }));
  };

  const isDropdownOpen = (dropdownName) => {
    return dropdownStates[dropdownName];
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      for (const key of Object.keys(dropdownRefs)) {
        if (dropdownRefs[key].current && !dropdownRefs[key].current.contains(event.target)) {
          setDropdownStates((prevStates) => ({ ...prevStates, [key]: false }));
        } else if(event.target.tagName === "INPUT" && isDropdownOpen(key)){
          setDropdownStates((prevStates) => ({ ...prevStates, [key]: false }));
        }

      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownRefs]);

  const searchForValue = (value) => {
    const target = dropdownItems.organization_of_teams_in_the_exercise_choices.find(obj => obj.value === Number(value));
    return target ? target.name : null;
  }

  return (
    <div className="create-trainings-exercise-elements-forms-3">
      <div className="internal-logic-elements-dropdown-columns">
        <div>
          <label className="black-16px-700">
            {props.t("Ball or mobile")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown1} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown1")}
            >
              <p
                className={
                  props.currentExercise.ball_or_mobile === null ? "" : "selected"
                }
              >
                {props.currentExercise.ball_or_mobile === null
                  ? `${props.t(currentDropdownNames.dropdown1)}`
                  :
                  props.t(
                      dropdownItems.ball_or_mobile_choices[
                        props.currentExercise.ball_or_mobile
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown1 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown1")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown1 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.ball_or_mobile_choices}
                currentExercise={props.currentExercise}
                valueToChange="ball_or_mobile"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("Number of players")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown2} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown2")}
            >
              <p
                className={
                  props.currentExercise.number_of_players_per_team === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.number_of_players_per_team === null
                  ? `${props.t(currentDropdownNames.dropdown2)}`
                  : props.t(
                      dropdownItems.number_of_players_per_team_choices[
                        props.currentExercise.number_of_players_per_team
                        ][1],
                    )}
              </p>

              {!dropdownStates.dropdown2 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown2")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown2 && (
              <MultipleChoiceDropdown
                dropdownOptions={
                  dropdownItems.number_of_players_per_team_choices
                }
                currentExercise={props.currentExercise}
                valueToChange="number_of_players_per_team"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("Tactical orientation")}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown3} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown3")}
            >
              <p
                className={
                  props.currentExercise.tatical_orientation === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.tatical_orientation === null
                  ? `${props.t(currentDropdownNames.dropdown3)}`
                  : props.t(
                      dropdownItems.tatical_orientation_choices[
                        props.currentExercise.tatical_orientation
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown3 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown3")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown3 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.tatical_orientation_choices}
                currentExercise={props.currentExercise}
                valueToChange="tatical_orientation"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
      </div>
      <div className="internal-logic-elements-dropdown-columns">
        <div>
          <label className="black-16px-700">
            {props.t("Action space")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown4} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown4")}
            >
              <p
                className={
                  props.currentExercise.space_of_action === null ? "" : "selected"
                }
              >
                {props.currentExercise.space_of_action === null
                  ? `${props.t(currentDropdownNames.dropdown4)}`
                  : props.t(
                      dropdownItems.space_of_action_choices[
                        props.currentExercise.space_of_action
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown4 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown4")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown4 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.space_of_action_choices}
                currentExercise={props.currentExercise}
                valueToChange="space_of_action"
                handleExerciseDataChange={props.handleExerciseDataChange}
                label="Principios de jogo"
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("Opposing players")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown5} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown5")}
            >
              <p
                className={
                  props.currentExercise.players_in_opposition === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.players_in_opposition === null
                  ? `${props.t(currentDropdownNames.dropdown5)}`
                  : props.t(
                      dropdownItems.players_in_opposition_choices[
                        props.currentExercise.players_in_opposition
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown5 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown5")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown5 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.players_in_opposition_choices}
                currentExercise={props.currentExercise}
                valueToChange="players_in_opposition"
                handleExerciseDataChange={props.handleExerciseDataChange}
                label="Principios de jogo"
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("Practicing goalkeepers")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown6} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown6")}
            >
              <p
                className={
                  props.currentExercise.goalkeepers_in_exercise === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.goalkeepers_in_exercise === null
                  ? `${props.t(currentDropdownNames.dropdown6)}`
                  : props.t(
                      dropdownItems.goalkeepers_in_exercise_choices[
                        props.currentExercise.goalkeepers_in_exercise
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown6 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown6")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown6 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.goalkeepers_in_exercise_choices}
                currentExercise={props.currentExercise}
                valueToChange="goalkeepers_in_exercise"
                handleExerciseDataChange={props.handleExerciseDataChange}
                label="Principios de jogo"
                t={props.t}
              />
            )}
          </div>
        </div>
      </div>

      <div className="internal-logic-elements-dropdown-columns">
        <div>
          <label className="black-16px-700">
            {props.t("Organization of teams in the exercise")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown7} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown7")}
            >
              <p
                className={
                  props.currentExercise.organization_of_teams_in_the_exercise === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.organization_of_teams_in_the_exercise === null
                  ? `${props.t(currentDropdownNames.dropdown7)}`
                  :  searchForValue(props.currentExercise.organization_of_teams_in_the_exercise)
                }
              </p>
              {!dropdownStates.dropdown7 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown7")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown7 && (
              <MultipleChoiceDropdown
                dropdownOptions={
                  dropdownItems.organization_of_teams_in_the_exercise_choices
                }
                currentExercise={props.currentExercise}
                valueToChange="organization_of_teams_in_the_exercise"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("m2 per player inside the field")}{" "}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown8} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown8")}
            >
              <p
                className={
                  props.currentExercise.m2_for_player_in_the_field === null ? "" : "selected"
                }
              >
                {props.currentExercise.m2_for_player_in_the_field === null
                  ? `${props.t(currentDropdownNames.dropdown8)}`
                  : props.t(
                      dropdownItems.m2_for_player_in_the_field_choices[
                        props.currentExercise.m2_for_player_in_the_field
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown8 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown8")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown8 && (
              <MultipleChoiceDropdown
                dropdownOptions={
                  dropdownItems.m2_for_player_in_the_field_choices
                }
                currentExercise={props.currentExercise}
                valueToChange="m2_for_player_in_the_field"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
        <div>
          <label className="black-16px-700">
            {props.t("Decision making")}
            <span className="webapp-asterisco">*</span>
          </label>
          <div ref={dropdownRefs.dropdown9} style={{ position: "relative" }}>
            <div
              className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
              onClick={() => toggleDropdown("dropdown9")}
            >
              <p
                className={
                  props.currentExercise.decision_making === null
                    ? ""
                    : "selected"
                }
              >
                {props.currentExercise.decision_making === null
                  ? `${props.t(currentDropdownNames.dropdown9)}`
                  : props.t(
                      dropdownItems.decision_making_choices[
                        props.currentExercise.decision_making
                        ][1],
                    )}
              </p>
              {!dropdownStates.dropdown9 ? (
                <div  onClick={(e) =>{ e.stopPropagation();toggleDropdown("dropdown9")}}> <BiSolidDownArrow /> </div>
              ) : (
                <BiSolidUpArrow />
              )}
            </div>
            {dropdownStates.dropdown9 && (
              <MultipleChoiceDropdown
                dropdownOptions={dropdownItems.decision_making_choices}
                currentExercise={props.currentExercise}
                valueToChange="decision_making"
                handleExerciseDataChange={props.handleExerciseDataChange}
                t={props.t}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NewExerciseElements = (props) => {
  const [dropdownItems, setDropdownItems] = useState(props.dropdownItems);
  const [gbo, setGbo] = useState(dropdownItems.general_bases_of_orientation);
  const [ga, setGa] = useState(dropdownItems.game_actions);
  const [pop, setPop] = useState(dropdownItems.principles_of_play);

  const dropdownRef = useRef(null);
  const generalBasesRef = useRef(null);
  const gameActionsRef = useRef(null);
  const principlesOfPlayRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        generalBasesRef.current &&
        !generalBasesRef.current.contains(event.target) &&
        gameActionsRef.current &&
        !gameActionsRef.current.contains(event.target) &&
        principlesOfPlayRef.current &&
        !principlesOfPlayRef.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }

      if (event.target.tagName === "INPUT" && !principlesOfPlayRef.current.contains(event.target)){
        setOpenDropdown(null);
      }

    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [openDropdown, setOpenDropdown] = useState(null);

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const handleGeneralObjectiveDropdown = () => {
    setOpenDropdown(
      openDropdown === "general_bases_of_orientation"
        ? null
        : "general_bases_of_orientation",
    );
  };

  const handleGamePrinciplesDropdown = () => {
    setOpenDropdown(
      openDropdown === "principles_of_play" ? null : "principles_of_play",
    );
  };

  const handleSpecialReferenceDropdown = () => {
    setOpenDropdown(openDropdown === "game_actions" ? null : "game_actions");
  };

  const [position, setIndex] = useState(-1);

  return (
    <div className="create-trainings-exercise-elements-forms-1">
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {props.t("General Bases Of Orientation")}{" "}
          <span className="webapp-asterisco">*</span>
        </label>
        <div ref={generalBasesRef}>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleGeneralObjectiveDropdown}
          >
            <p
              className={
                props.currentExercise.general_bases_of_orientation === null
                  ? ""
                  : "selected"
              }
            >
              {props.currentExercise.general_bases_of_orientation === null
                ? props.t("General Bases Of Orientation")
                : props.t(
                    props.currentExercise.general_bases_of_orientation.name,
                  )}
            </p>
            {openDropdown !== "general_bases_of_orientation" ? (
              <div  onClick={(e) =>{ e.stopPropagation();handleGeneralObjectiveDropdown()}}> <BiSolidDownArrow /> </div>
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "general_bases_of_orientation" && (
            <div
              className="create-trainings-exercise-elements-custom-dropdown-groups black-16px-400"
              onMouseLeave={() => setIndex(-1)}
            >
              {gbo.map((group, index) => {
                let options = group[Object.keys(group)[0]];
                return (
                  <div
                    key={index}
                    className="create-trainings-exercise-elements-custom-dropdown-group"
                  >
                    <p onMouseEnter={() => setIndex(index)}>
                      {props.t(Object.keys(group)[0]) }
                    </p>
                    {position === index && (
                      <MultipleChoiceDropdown
                        dropdownOptions={options}
                        currentExercise={props.currentExercise}
                        valueToChange="general_bases_of_orientation"
                        handleExerciseDataChange={
                          props.handleExerciseDataChange
                        }
                        label="General Bases"
                        typeOfKey="foreignKey"
                        css={true}
                        t={props.t}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {/*openDropdown === 'general_bases_of_orientation' && <MultipleChoiceDropdown dropdownOptions={dropdownItems.general_bases_of_orientation} currentExercise={props.currentExercise} valueToChange="general_bases_of_orientation" handleExerciseDataChange={props.handleExerciseDataChange} label="General Bases" typeOfKey="foreignKey"/>*/}
        </div>
      </div>
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {props.t("Game Actions")} <span className="webapp-asterisco">*</span>
        </label>
        <div ref={gameActionsRef}>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleSpecialReferenceDropdown}
          >
            <p
              className={
                props.currentExercise.game_actions === null ? "" : "selected"
              }
            >
              {props.currentExercise.game_actions === null
                ? props.t("Game Actions")
                : props.t(props.currentExercise.game_actions.name)}
            </p>

            {openDropdown !== "game_actions" ? (
              <div  onClick={(e) =>{ e.stopPropagation();handleSpecialReferenceDropdown()}}> <BiSolidDownArrow /> </div>
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "game_actions" && (
            <div
              className="create-trainings-exercise-elements-custom-dropdown-groups black-16px-400"
              onMouseLeave={() => setIndex(-1)}
            >
              {ga.map((group, index) => {
                let options = group[Object.keys(group)[0]];
                return (
                  <div
                    key={index}
                    className="create-trainings-exercise-elements-custom-dropdown-group"
                  >
                    <p onMouseEnter={() => setIndex(index)}>
                      {props.t(Object.keys(group)[0]) }
                    </p>
                    {position === index && (
                      <MultipleChoiceDropdown
                        dropdownOptions={options}
                        valueToChange="game_actions"
                        currentExercise={props.currentExercise}
                        handleExerciseDataChange={
                          props.handleExerciseDataChange
                        }
                        label="Referencia Especial"
                        typeOfKey="foreignKey"
                        css={true}
                        t={props.t}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="create-trainings-exercise-elements-dropdowns">
        <label>
          {props.t("Principles Of Play")}{" "}
          <span className="webapp-asterisco">*</span>
        </label>
        <div ref={principlesOfPlayRef}>
          <div
            className="create-trainings-exercise-elements-custom-dropdown black-16px-400"
            onClick={handleGamePrinciplesDropdown}
          >
            <p
              className={
                props.currentExercise.principles_of_play.length === 0
                  ? ""
                  : "selected"
              }
            >
              {props.currentExercise.principles_of_play.length === 0
                ? props.t("Principles Of Play")
                : props.currentExercise.principles_of_play
                    .map((principle) => props.t(principle.name))
                    .join(", ")}
            </p>
            {openDropdown !== "principles_of_play" ? (
              <div  onClick={(e) =>{ e.stopPropagation();handleGamePrinciplesDropdown()}}> <BiSolidDownArrow /> </div>
            ) : (
              <BiSolidUpArrow />
            )}
          </div>
          {openDropdown === "principles_of_play" && (
            <div
              className="create-trainings-exercise-elements-custom-dropdown-groups black-16px-400"
              onMouseLeave={() => setIndex(-1)}
            >
              {pop.map((group, index) => {
                let options = group[Object.keys(group)[0]];
                return (
                  <div
                    key={index}
                    className="create-trainings-exercise-elements-custom-dropdown-group"
                  >
                    <p onMouseEnter={() => setIndex(index)}>
                      {props.t(Object.keys(group)[0]) }
                    </p>
                    {position === index && (
                      <MultipleChoiceDropdown
                        dropdownOptions={options}
                        currentExercise={props.currentExercise}
                        valueToChange="principles_of_play"
                        handleExerciseDataChange={
                          props.handleExerciseDataChange
                        }
                        label="Principios de jogo"
                        typeOfKey="foreignKeyList"
                        css={true}
                        t={props.t}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export {
  ExerciseElements,
  ExerciseDensityElements,
  InternalLogicElements,
  NewExerciseElements,
};
