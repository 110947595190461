import React, { useEffect, useRef, useState,useContext} from 'react';
import Chart from 'chart.js/auto';
import './treinospage1.css';
import axios from 'axios';
import { IoPerson } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";


function TreinoChartComponent({sessionID}) {
  const { t } = useTranslation(['training-chars']);
  const [fetchedData, setFetchedData] = useState([]);
  const [preSessionAnswered, setPreSessionAnswered] = useState('0/0'); 
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };


  useEffect(() => {

    const fetchData = async () => {
      try {
        //const checkresponse = await axios.get(process.env.REACT_APP_BACKEND + `training/pre_session_form/list?session=${sessionID}`, { headers });
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}training/pre_session_form/list?session=${sessionID}`, { headers });
        setFetchedData("isto" ,response.data);
        
        const sleepQualityData = response.data.map(item => item.sleep_quality);
        const sleepQualityDataWithoutNull = sleepQualityData.filter(val => val !== null);
        const countSleepQuality = sleepQualityDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      
        const allPossibleValues = Array.from({ length: 11 }, (_, i) => i);
        allPossibleValues.forEach((val) => {
          if (!(val in countSleepQuality)) {
            countSleepQuality[val] = 0;
          }
        });
      
        const readinessData = response.data.map(item => item.readiness);
        const readinessDataWithoutNull = readinessData.filter(val => val !== null);
        const countReadinessQuality = readinessDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      
        const allPossibleValuesReadiness = Array.from({ length: 11 }, (_, i) => i);
        allPossibleValuesReadiness.forEach((val) => {
          if (!(val in countReadinessQuality)) {
            countReadinessQuality[val] = 0;
          }
        });
      
        const fatigueData = response.data.map(item => item.fatigue);
        const fatigueDataWithoutNull = fatigueData.filter(val => val !== null);
        const countFatigueQuality = fatigueDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      
        const allPossibleValuesFatigue = Array.from({ length: 11 }, (_, i) => i);
        allPossibleValuesFatigue.forEach((val) => {
          if (!(val in countFatigueQuality)) {
            countFatigueQuality[val] = 0;
          }
        });
      
        const domsData = response.data.map(item => item.doms);
        const domsDataWithoutNull = domsData.filter(val => val !== null);
        const countDomsQuality = domsDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      
        const allPossibleValuesDoms = Array.from({ length: 11 }, (_, i) => i);
        allPossibleValuesDoms.forEach((val) => {
          if (!(val in countDomsQuality)) {
            countDomsQuality[val] = 0;
          }
        });
      
        const filteredData = response.data.filter(item => {
          return (
            item.sleep_quality !== null &&
            item.fatigue !== null &&
            item.doms !== null &&
            item.readiness !== null
          );
        }); 
      
        const playerNamesBySleepQuality = Array.from({ length: 11 }, () => []);
        const playerNamesByReadinessQuality = Array.from({ length: 11 }, () => []);
        const playerNamesByFatigueQuality = Array.from({ length: 11 }, () => []);
        const playerNamesByDomsQuality = Array.from({ length: 11 }, () => []);
      
        filteredData.forEach((item) => {
          playerNamesBySleepQuality[item.sleep_quality].push(item.player);
          playerNamesByFatigueQuality[item.fatigue].push(item.player);
          playerNamesByDomsQuality[item.doms].push(item.player);
          playerNamesByReadinessQuality[item.readiness].push(item.player);
        });

        
        try {
            
            
            const additionalResponse = await axios.get(`${process.env.REACT_APP_BACKEND}training/session/${sessionID}`, { headers });            // Process additional data or set it in state as needed
            
            
            // Extract the number of people that awnsered the preform
            const { preSessionAwnsered } = additionalResponse.data;
            setPreSessionAnswered(preSessionAwnsered);
            
          } catch (error) {
            console.error('Error fetching additional data:', error);
          }
        

          

        const data1 = {
          labels: [`${t('No sleep')}   0`, ' 1', `${t('Very bad')} 2`, '3', `${t('Moderate')} 4`, '5', `${t('Good')} 6`, '7',   `${t('Very good')} 8`, '9', `${t('Excellent')}   10`],
          datasets: [{
            label: 'Sleep Quality',
            data: Object.values(countSleepQuality),

            backgroundColor: [
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };


        const data2 = {
          labels: [
            `${t('Unavailable')} 0`,
            '1',
            `${t('Little available')} 2`,
            '3',
            `${t('Moderate')} 4`,
            '5',
            `${t('Available')} 6`,
            '7',
            `
            ${t('Very available')} 8`,
            '9',
            `${t('Totally available')} 10`],
          datasets: [{
            label: 'Readiness',
            data: Object.values(countReadinessQuality),
            backgroundColor: [
              'rgba(252, 165, 85, 1)', // Red color for values from 0 to 5
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)', // Red color for value 5
              'rgba(105, 120, 249, 1)', // Blue color for values from 6 to 10
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };

        const data3 = {
          labels: [`${t('Exhausted')} 0`, '1', `${t('Very tired')} 2`, ' 3', `${t('Moderate')} 4`, '5', `${t('Little tired')} 6`, '7', `${t('Not tired')} 8`, '9', `${t('Totally rested')} 10`],
          datasets: [{
            label: 'Fatigue',
            data: Object.values(countFatigueQuality),
            backgroundColor: [
              'rgba(252, 165, 85, 1)', // Red color for values from 0 to 5
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)', // Red color for value 5
              'rgba(105, 120, 249, 1)', // Blue color for values from 6 to 10
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };

        const data4 = {
          labels: [`${t('Completely sore')} 0`, '1', `${t('Very sore')} 2`, '3', `${t('Sore')} 4`, '5', `${t('Little sore')} 6`, '7', `${t('Not sore')} 8`, '9', `${t('Not at all sore')} 10`],
          datasets: [{
            label: 'Doms',
            data: Object.values(countDomsQuality),
            backgroundColor: [
              'rgba(252, 165, 85, 1)', // Red color for values from 0 to 5
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)', // Red color for value 5
              'rgba(105, 120, 249, 1)', // Blue color for values from 6 to 10
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };


        const createChart = (chartRef, data) => {
          if (chartRef.current) {
            if (chartRef.current.chart) {
              chartRef.current.chart.destroy(); // Destroy the previous chart instance
            }

            const ctx = chartRef.current.getContext('2d');
            chartRef.current.chart = new Chart(ctx, data);
          }
        };



        // Chart 1
        createChart(chartRef1, {
          type: 'bar',
          data: data1,
          options: {
            indexAxis: 'y',
            responsive: true,

            plugins: {
              legend: {
                display: false,
              },

            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                stepSize: 1,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,

            plugins: {

              tooltip: {

                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label;
                      const totalDataPoints = Object.values(countSleepQuality).reduce((acc, val) => acc + val, 0);
                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1];
                      const num_people = countSleepQuality[yValueNum];
                      const percentage = parseInt((num_people / totalDataPoints) * 100);
                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },
                  
                  label: function (context) {

                    const sleepQuality = parseInt(context.parsed.y);
                    const playerNames = playerNamesBySleepQuality[sleepQuality] || [];

                    // Display player names for the bar's key
                    return playerNames;
                  }
                }
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              legend: {
                display: false,
              }
            }
          },
        });

        // Chart 2
        createChart(chartRef2, {
          type: 'bar',
          data: data2,
          options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },

            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                max: 10,
                stepSize: 1,
                beginAtZero: true,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,
            plugins: {

              tooltip: {

                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label; // Assuming the y value is in the label property
                      const totalDataPoints = data2.datasets[tooltipItems[0].datasetIndex].data.reduce((acc, val) => acc + val, 0);

                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1]; // Access the last element of the array

                      const num_people = parseInt(countReadinessQuality[yValueNum])
                      const percentage = parseInt(num_people / totalDataPoints * 100)

                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },
                  label: function (context) {

                    const readinessQuality = parseInt(context.parsed.y);
                    const playerNames = playerNamesByReadinessQuality[readinessQuality] || [];

                    // Display player names for the bar's key
                    return playerNames;
                  }
                }
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              legend: {
                display: false,
              }
            }
          },
        });

        // Chart 3
        createChart(chartRef3, {
          type: 'bar',
          data: data3,
          options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
                text: ['Fadiga', ''],
                align: 'start',
                font: {
                  size: 16,
                },
                lineHeight: 1.2,
                color: 'black',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                max: 10,
                stepSize: 1,
                beginAtZero: true,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,
            plugins: {

              tooltip: {

                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label; // Assuming the y value is in the label property
                      const totalDataPoints = data3.datasets[tooltipItems[0].datasetIndex].data.reduce((acc, val) => acc + val, 0);

                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1]; // Access the last element of the array

                      const num_people = parseInt(countFatigueQuality[yValueNum])
                      const percentage = parseInt(num_people / totalDataPoints * 100)

                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },
                  label: function (context) {

                    const fatigueQuality = parseInt(context.parsed.y);
                    const playerNames = playerNamesByFatigueQuality[fatigueQuality] || [];

                    // Display player names for the bar's key
                    return playerNames;
                  }
                }
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              legend: {
                display: false,
              }
            }

          },
        });
        createChart(chartRef4, {
          type: 'bar',
          data: data4,
          options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              tooltip: {
                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label;
                      const totalDataPoints = data4.datasets[tooltipItems[0].datasetIndex].data.reduce((acc, val) => acc + val, 0);
                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1];
                      const num_people = parseInt(countDomsQuality[yValueNum]);
                      const percentage = parseInt(num_people / totalDataPoints * 100);
                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },
                  label: function (context) {
                    const domsQuality = parseInt(context.parsed.y);
                    const playerNames = playerNamesByDomsQuality[domsQuality] || [];
                    return playerNames;
                  }
                }
              },
            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                max: 10,
                stepSize: 1,
                beginAtZero: true,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,
          },
        });
        

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    
    

  }, [sessionID]); 

  return (
    <div className="chart-card-combined01">
      <div className='chart-header'>
        <h2 className="chart-title">{t('Before training')}</h2>
        <span className="icon-person-text">{preSessionAnswered}<IoPerson color="#626472" size={22}/></span>
      </div>
      <div className="chart-column">
        <div className='chart-column01-text'>
          <h4 className='chart-column01-title'>{t('Sleep quality')}:</h4>
        </div>
        <canvas ref={chartRef1}></canvas>
      </div>
      <div className="chart-column">
        <div className='chart-column01-text'>
          <h4 className='chart-column01-title'>{t('Readiness')}:</h4>
        </div>
        <canvas ref={chartRef2}></canvas>
      </div>
      <div className="chart-column">
        <div className='chart-column01-text'>
          <h4 className='chart-column01-title'>{t('Fatigue')}:</h4>
        </div>
        <canvas ref={chartRef3}></canvas>
      </div>
      <div className="chart-column">
        <div className='chart-column01-text'>
          <h4 className='chart-column01-title'>{t('Muscle Soreness')}:</h4>
        </div>
        <canvas ref={chartRef4}></canvas>
      </div>
    </div>
  );
}

export default TreinoChartComponent;