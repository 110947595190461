import { useState, useEffect, useContext } from 'react';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from 'react-router-dom';
import './CreateTrainingPage.css';
import { ExerciseDensityElements, InternalLogicElements, NewExerciseElements } from '../../webAppComponents/CreateTrainingItems/CreateTrainingItems';
import axios from 'axios';
import ImportItemsPopup from '../../webAppComponents/ImportItemsPopup/ImportItemsPopup';
import AuthContext from '../../context/AuthContext';
import CoachPaint from '../../webAppComponents/CoachPaint/CoachPaint';
import Drawing from '../../webAppComponents/CoachPaint/CoachPaintComponents/Drawing';
import fullField from "../../webAppComponents/CoachPaint/CoachPaintAssets/full-field.svg"
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationPopup from '../../webAppComponents/ConfirmationPopup/Popup'

import BckLogo from '../../webAppAssets/BackgroundLogo.svg'

const CreateTrainingPage = (props) => {
    const { t } = useTranslation(['create-training-page']);
    const [waitingForItems, setWaitingForItems] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [step, setStep] = useState(1);
    const [trainingDropdownItems, setTrainingDropdownItems] = useState([]);
    const [answeredStep1, setAnsweredStep1] = useState(false);
    const [answeredStep2, setAnsweredStep2] = useState(false);
    const [answeredStep3, setAnsweredStep3] = useState(false);
    const [openImportExercisePopup, setOpenImportExercisePopup] = useState(false);
    const [allExercisesList, setAllExercisesList] = useState([]);
    const [openCoachPaint, setOpenCoachPaint] = useState(false);
    const { authTokens } = useContext(AuthContext);
    const [drawing, setDrawing] = useState({
        lines: [],
        image: { image: fullField, label: "Campo inteiro" },
        stamps: [],
    });

    const uniqueStageId = `stage-id-${drawing?.id}`;
    const [exerciseList, setExerciseList] = useState([]);
    const [editMode, setEditMode] = useState(location.state ? location.state.editMode : false)
    const [checkEditPopup, setCheckEditPopup] = useState(false)


    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const [currentExerciseId, setCurrentExerciseId] = useState(
        editMode ?
            location.state.training.id: null
    )

    const [currentExercise, setCurrentExercise] = useState(
            {
                name: null,
                description: null,
                general_bases_of_orientation: null,
                principles_of_play: [],
                game_actions: null,
                num_reps: null,
                rep_duration: null,
                rest_time: null,
                ball_or_mobile: null,
                space_of_action: null,
                organization_of_teams_in_the_exercise: null,
                number_of_players_per_team: null,
                players_in_opposition: null,
                m2_for_player_in_the_field: null,
                tatical_orientation: null,
                goalkeepers_in_exercise: null,
                decision_making: null,
                drawing: null,
                exerciseInUse: null
            });


    const handleOpenCoachPaint = () => {
        setOpenCoachPaint(!openCoachPaint);
    }

    const handleSelectedExerciseChange = async (id) => {
        setCurrentExerciseId(id);
        setStep(1);

    };

    const handleOpenImportExercisePopup = () => {
        getAllExercises();

    }
    const handleReturn = () => {
        navigate('/webapp/trainings')
    }

    const previousStep = () => {
        if (step === 1) {
            setStep(1)
        }
        else {
            setStep(step - 1)
        }
    }

    const nextStep = () => {
        if (step === 1 && !answeredStep1) {
            toast.warning(t('step1-warning'));
            return;
        }
        else if (step === 2 && !answeredStep2) {
            toast.warning(t('step2-warning'));
            return;
        }
        else if (step === 3 && !answeredStep3 ) {
            toast.warning(t('step3-warning'));
            return;
        }

        if (step === 4) {
            setStep(4)
        }
        else {
            setStep(step + 1)
        }
    }

    const skipToStep = (step) => {
        if (step === 1) {
            setStep(1)
        } else if (step === 2 && answeredStep1) {
            setStep(2)
        } else if ( step === 3 && answeredStep2 && answeredStep1) {
            setStep(3)
        } else if (step === 4 && answeredStep3 && answeredStep2 && answeredStep1) {
            setStep(4)
        }
        else {
            toast.warning(t('steps-warning'));
            return;
        }
    }

    const handleExerciseDataChange = (field, value) => {
      setCurrentExercise((prevData) => {
            const isValueEqual = JSON.stringify(prevData[field]) === JSON.stringify(value);

            let updatedData;

            if (isValueEqual) {
                updatedData = {
                    ...prevData,
                    [field]: null,
                };
            } else {
                if (field === "principles_of_play" && Array.isArray(prevData[field])) {
                    const hasEqualValue = prevData[field].some((item) => item.id === value.id);

                    if (hasEqualValue) {
                        updatedData = {
                            ...prevData,
                            [field]: prevData[field].filter((item) => item.id !== value.id),
                        };
                    } else {
                        updatedData = {
                            ...prevData,
                            [field]: [...prevData[field], value],
                        };
                    }
                } else {
                    updatedData = {
                        ...prevData,
                        [field]: value,
                    };
                }
            }

            // Do any other logic related to the state update here
            return updatedData;
        });
    };

    const importExercise = (id) => {
        setCurrentExerciseId(id)
        setOpenImportExercisePopup(!openImportExercisePopup);
    }

    const handleDrawingDataChange = (field, value) => {
        setDrawing((prevData) => {
            return {
                ...prevData,
                [field]: value,
            };
        })
    }

    //PARA SALVAR EXERICICO
    // Este useEffect serve para ir buscar os dados do exercicio que estamos a editar, ou caso estejamos a criar um novo exercicio mete tudo para null
    useEffect(() => {
        const fetchData = async () => {
        try {
            if (currentExerciseId === null) {
                setCurrentExercise({
                    name: null,
                    description: null,
                    general_bases_of_orientation: null,
                    principles_of_play: [],
                    game_actions: null,
                    num_reps: null,
                    rep_duration: null,
                    rest_time: null,
                    ball_or_mobile: null,
                    space_of_action: null,
                    organization_of_teams_in_the_exercise: null,
                    number_of_players_per_team: null,
                    players_in_opposition: null,
                    m2_for_player_in_the_field: null,
                    tatical_orientation: null,
                    goalkeepers_in_exercise: null,
                    decision_making: null,
                    drawing: null,
                    exerciseInUse: null
                });
                setDrawing({
                    id: null,
                    lines: [],
                    image: {image: fullField, label: "Campo inteiro"},
                    stamps: [],
                })
            } else {
                const response = await axios.get(process.env.REACT_APP_BACKEND + `training/exercise/${currentExerciseId}`, {headers});
                setCurrentExercise({
                    id: response.data.id,
                    name: response.data.name,
                    description: response.data.description,
                    general_bases_of_orientation: response.data.general_bases_of_orientation,
                    principles_of_play: response.data.principles_of_play,
                    game_actions: response.data.game_actions,
                    num_reps: response.data.num_reps,
                    rep_duration: response.data.rep_duration,
                    rest_time: response.data.rest_time,
                    ball_or_mobile: response.data.ball_or_mobile,
                    space_of_action: response.data.space_of_action,
                    organization_of_teams_in_the_exercise: response.data.organization_of_teams_in_the_exercise,
                    number_of_players_per_team: response.data.number_of_players_per_team,
                    players_in_opposition: response.data.players_in_opposition,
                    m2_for_player_in_the_field: response.data.m2_for_player_in_the_field,
                    tatical_orientation: response.data.tatical_orientation,
                    goalkeepers_in_exercise: response.data.goalkeepers_in_exercise,
                    decision_making: response.data.decision_making,
                    drawing: response.data.drawing,
                    exerciseInUse: response.data.exercise_in_use,
                    archive: response.data.archive
                });
                setDrawing({
                    id: response.data.drawing.id,
                    lines: response.data.drawing.lines,
                    image: response.data.drawing.image,
                    stamps: response.data.drawing.stamps,
                })
            }
            } catch (error) {
                toast.error(t('exercise-data-error'));
            }
        };
        fetchData();


    }, [currentExerciseId]);

    //funcao para ir buscar as opcoes
    const getDropdownOptions = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + 'training/exercise_options/list', { headers })
            setTrainingDropdownItems(response.data)
            setWaitingForItems(false)
        }
        catch (error) {
            toast.error(t('fetch-error'));
        }

    }

    //funcao para ir buscar as opcoes on start ( use effect )
    useEffect(() => {
        getDropdownOptions()
    }, [])


    const checkNextStep = () => {
        if (step === 1) {
            if (drawing.lines.length!=0 || drawing.stamps.length!=0) {
                setAnsweredStep1(true);
            }
            else {
                setAnsweredStep1(false)
            }
        }
        else if (step === 2) {

            if (
                currentExercise &&
                currentExercise.general_bases_of_orientation !== null &&
                currentExercise.principles_of_play.length > 0 &&
                currentExercise.game_actions !== null
                )
            {
                setAnsweredStep2(true)

            }
            else {
                setAnsweredStep2(false)
            }
        }

        else if (step === 3) {
            if (
                currentExercise &&
                currentExercise.num_reps !== null &&
                currentExercise.rep_duration !== null &&
                currentExercise.rest_time !== null
            ) {

                setAnsweredStep3(true)
            }
            else {

                setAnsweredStep3(false)
            }
        }
    }

    useEffect(() => {
        checkNextStep()
    }, [currentExercise, step, drawing]);


    const handleTimeInputChange = (rest_time, event) => {
      event.preventDefault();

      const input = event.target.value;
      const split_input = input.split(":")

      if (split_input.some((element) => isNaN(element))) return
      if (split_input.length != 3) return;
      if(split_input.some((splited) => splited.length > 2)) return

      if (rest_time) {
        handleExerciseDataChange('rest_time', input)
      } else {
        handleExerciseDataChange('rep_duration',input )
      }

      return;
    };

    const fromStringToSeconds = (time_string) => {
      const [hours, minutes, seconds] = time_string.split(':').map(Number);
      const time_seconds = hours * 3600 + minutes * 60 + seconds;

      return time_seconds
    }

    const totalTimeSeconds = () => {
      if (!currentExercise?.rep_duration || !currentExercise?.rest_time) return 0;
      return  ( currentExercise?.num_reps * fromStringToSeconds(currentExercise?.rep_duration) + (currentExercise?.num_reps * fromStringToSeconds(currentExercise?.rest_time) ) );
    }

    const toStringFormat = (time) => {
      if (typeof time === "string")
        return time

      // from seconds to hh:mm:ss
      let hours = Math.floor(time / 3600);
      if (hours / 10 < 1)
        hours = `0${hours}`

      let minutes = Math.floor((time - (hours * 3600)) / 60);
      if (minutes / 10 < 1)
        minutes = `0${minutes}`

      let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
      if (seconds / 10 < 1)
        seconds = `0${seconds}`

      return (`${hours}:${minutes}:${seconds}`);
    }

    const calculateTimeDensity = () => {
      if (!currentExercise?.rep_duration) return 0;
      if (currentExercise?.rep_duration && !currentExercise?.rest_time) return 100;

      const rep_seconds = fromStringToSeconds(currentExercise?.rep_duration);
      const rest_seconds = fromStringToSeconds(currentExercise?.rest_time)

      return Math.round(rep_seconds / (rest_seconds + rep_seconds) * 100 );
    }

    const [timeDensity, setTimeDensity] = useState(calculateTimeDensity())

    useEffect(() => {
      setTimeDensity(calculateTimeDensity());
    }, [currentExercise?.rep_duration, currentExercise?.rest_time]);

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    const createExercise = async () => {

        let folder = null;
        if (location.state)
            folder = location.state.folderId

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'training/exercise/create', {
              previousId: currentExercise.id,
                name: currentExercise.name,
                description: currentExercise.description,
                general_bases_of_orientation: currentExercise.general_bases_of_orientation.id,
                principles_of_play: currentExercise.principles_of_play.map(item => item.id),
                game_actions: currentExercise.game_actions.id,
                num_reps: currentExercise.num_reps,
                rep_duration: currentExercise.rep_duration,
                rest_time: currentExercise.rest_time,
                ball_or_mobile: currentExercise.ball_or_mobile,
                space_of_action: currentExercise.space_of_action,
                organization_of_teams_in_the_exercise: currentExercise.organization_of_teams_in_the_exercise,
                number_of_players_per_team: currentExercise.number_of_players_per_team,
                players_in_opposition: currentExercise.players_in_opposition,
                m2_for_player_in_the_field: currentExercise.m2_for_player_in_the_field,
                tatical_orientation: currentExercise.tatical_orientation,
                goalkeepers_in_exercise: currentExercise.goalkeepers_in_exercise,
                decision_making: currentExercise.decision_making,
                drawing: drawing,
                folder: folder
            }, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
            });

            if ( response.status === 201 || response.status === 200 && editMode=== true) {
                toast.success(t('exercise-save'));
                navigate('/webapp/trainings')
            }
            else if ( response.status === 201 || response.status === 200 && editMode === false) {
                const newExercise = {id: response.data.id, name: response.data.name};
                toast.success(t('exercise-save'));

                setExerciseList([...exerciseList, newExercise]);
                //wait
                setCurrentExerciseId(null);
                setStep(1);
            }
            else {
                toast.error(t('Error saving the exercise, please try again!'));
            }

        }
        catch (error) {
            console.log(error)
        }
    }

    const editExercise = async () => {
        try {
            // SE ESTIVERMOS A EDITAR UM EXERCICIO JÁ EXISTENTE
            const response = await axios.patch(process.env.REACT_APP_BACKEND + `training/exercise/update/${currentExerciseId}`, {
                    name: currentExercise.name,
                    description: currentExercise.description,
                    general_bases_of_orientation: currentExercise.general_bases_of_orientation.id,
                    principles_of_play: currentExercise.principles_of_play.map(item => item.id),
                    game_actions: currentExercise.game_actions.id,
                    num_reps: currentExercise.num_reps,
                    rep_duration: currentExercise.rep_duration,
                    rest_time: currentExercise.rest_time,
                    ball_or_mobile: currentExercise.ball_or_mobile,
                    space_of_action: currentExercise.space_of_action,
                    organization_of_teams_in_the_exercise: currentExercise.organization_of_teams_in_the_exercise,
                    number_of_players_per_team: currentExercise.number_of_players_per_team,
                    players_in_opposition: currentExercise.players_in_opposition,
                    m2_for_player_in_the_field: currentExercise.m2_for_player_in_the_field,
                    tatical_orientation: currentExercise.tatical_orientation,
                    goalkeepers_in_exercise: currentExercise.goalkeepers_in_exercise,
                    decision_making: currentExercise.decision_making,
                    drawing: drawing

                }, { headers });

                if ( response.status === 201 || response.status === 200 && editMode=== true) {
                    toast.success(t('exercise-save'));
                    navigate('/webapp/trainings')
                }
                else if ( response.status === 201 || response.status === 200) {
                    const newExercise = {id: response.data.id, name: response.data.name};
                    toast.success(t('exercise-save'));

                    setExerciseList([...exerciseList, newExercise]);
                    setCurrentExerciseId(null)
                    toast.success(t('exercise-save'));
                }
                else {
                    toast.error(t('Error saving the exercise, please try again!'));
                }


            } catch (error) {
                console.log(error)
            }
    }

    const saveExercise = async (e) => {
        if (currentExercise.ball_or_mobile === null ||
            currentExercise.space_of_action === null ||
            currentExercise.organization_of_teams_in_the_exercise === null ||
            currentExercise.number_of_players_per_team === null ||
            currentExercise.players_in_opposition === null ||
            currentExercise.m2_for_player_in_the_field === null ||
            currentExercise.tatical_orientation === null ||
            currentExercise.goalkeepers_in_exercise === null ||
            currentExercise.decision_making === null) {

            toast.warning(t('step4-warning'));
        }

        if (currentExercise.description === null || currentExercise.name === null) {
            toast.warning(t('name-warning'))
            return;
        }

        //Se estivermos a criar um exercicio novo e não estivermos a editar um existente
        if (currentExercise.exerciseInUse === true || !editMode) {
            createExercise()
        }
        else {
            editExercise()
        }

    }

    return (
        <div className={`webapp-create-training-page ${openCoachPaint ? 'create-training-stop-overflowing' : ''}`}>
            <div className="webapp-coach-paint-menu-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                <h1>{t('New Exercise')}</h1>
            </div>
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
            </div>
            <div className="webapp-create-training-header">
                <div className="webapp-create-training-header-left-inputs">
                    <div >
                        <label>{t('Exercise Name')}<span>*</span></label>
                        <input
                            placeholder={t('Exercise Name')}
                            type="text"
                            maxlength="100"
                            value={currentExercise.name || ""}
                            onChange={(e) => handleExerciseDataChange('name', e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label>{t('Short Description')}<span>*</span></label>
                        <input
                            placeholder={t('Short Description')}
                            type="text"
                            maxlength="250"
                            value={currentExercise.description || ''}
                            onChange={(e) => handleExerciseDataChange('description', e.target.value)}
                        ></input>
                    </div>

                </div>
                <div className="webapp-create-training-header-time">
                    <label>{t('Total Duration')}</label>
                    <div className="webapp-create-training-header-time-box">
                        {currentExercise.rep_duration !== null && currentExercise.rest_time !== null && currentExercise.num_reps !== null ? (
                        <span className="dark-blue-16px-600">{toStringFormat(totalTimeSeconds())} min</span>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="webapp-create-training-exercise">
                <h1>{t('Exercises')}</h1>
                <div className="webapp-create-training-exercise-container">
                    <div className="webapp-create-training-exercise-card">
                        {exerciseList?.map((exercise) => (
                            <div key={exercise.id} className={`webapp-create-training-exercise-new-card ${currentExerciseId === exercise.id ? 'webapp-create-exercise-selected-exercise' : ''}`} onClick={() => handleSelectedExerciseChange(exercise.id)}>
                                <span className="black-16px-600" >{exercise.name}</span>
                            </div>
                        ))}
                        <div className={`webapp-create-training-exercise-new-card ${!currentExerciseId ? 'webapp-create-exercise-selected-exercise' : ''}`} onClick={() => handleSelectedExerciseChange(null)}>
                            <span className="black-16px-600">+ {t('New')}</span>
                        </div>
                    </div>
                    <div className="webapp-create-training-exercise-info">
                        <div className="webapp-create-training-exercise-info-header">
                            <h2>{!currentExercise.name ? t('New Exercise') : `${currentExercise.name}`}</h2>
                        </div>
                        {!waitingForItems ? (
                            <div className="webapp-create-training-exercise-info-form-drawing">
                                <div className="webapp-create-training-exercise-info-form">
                                    <div className={`${step === 1 ? "webapp-create-training-selected-exercise-drawing" : 'webapp-create-training-exercise-drawing'}`} onClick={() => handleOpenCoachPaint()} id={`${uniqueStageId}-display`}>
                                        {drawing ? (<Drawing justDisplay={true} lines={drawing.lines} campoState={drawing.image.image} stamps={drawing.stamps} stageId={`${uniqueStageId}-display`} exerciseName={currentExercise.name} exerciseDescription={currentExercise.description} />) : (<img src="https://i.imgur.com/qebbL6n.png"></img>)}
                                        <button>{t('Draw')}</button>
                                    </div>
                                    <div className="create-training-info-form-step-header">
                                        <span className={`${step === 1 && 'create-training-info-form-selected-step'}`} onClick={() => skipToStep(1)}>1</span>

                                        <h3>{t('Exercise outline')}</h3>
                                    </div>
                                    <div className="create-training-info-form-step-items">
                                        {step === 1 ?
                                            <div className={`${step === 1 ? 'create-training-drawing-selected' : ''}`}>
                                                <p className="black-16px-400 ">{t('Want to add an outline of the exercise?')}</p>
                                                <p className="black-16px-400 ">{t('Click on the Draw button')}</p>
                                            </div>
                                            : null}

                                    </div>
                                    <div className="create-training-info-form-step-header">
                                        <span className={`${step === 2 && 'create-training-info-form-selected-step'}`} onClick={() => skipToStep(2)}>2</span>
                                        <h3>{t('Elements of the phase or moment')}<p>{t('of the game to be developed in the exercise')}</p></h3>
                                    </div>
                                    <div className="create-training-info-form-step-items">
                                        {/*step === 2 ? <ExerciseElements currentExercise={currentExercise} dropdownItems={trainingDropdownItems} handleExerciseDataChange={handleExerciseDataChange} /> : null*/}
                                        {step === 2 ? <NewExerciseElements currentExercise={currentExercise} dropdownItems={trainingDropdownItems} handleExerciseDataChange={handleExerciseDataChange} t={t}/> : null}
                                    </div>
                                    <div className="create-training-info-form-step-header">
                                        <span className={`${step === 3 && 'create-training-info-form-selected-step'}`} onClick={() => skipToStep(3)}>3</span>
                                        <h3>{t('Elements of the density of the exercise')}</h3>
                                    </div>

                                    <div className="create-training-info-form-step-items">
                      {step === 3 ? <ExerciseDensityElements currentExercise={currentExercise} handleExerciseDataChange={handleExerciseDataChange} handleTimeInputChange={handleTimeInputChange} timeDensity={timeDensity} t={t}/> : null}
                                    </div>
                                    <div className="create-training-info-form-step-header">
                                        <span className={`${step === 4 && 'create-training-info-form-selected-step'}`} onClick={() => skipToStep(4)}>4</span>
                                        <h3>{t('Elements of internal logic of the game')}</h3>
                                    </div>
                                    {step === 4 ? <InternalLogicElements currentExercise={currentExercise} dropdownItems={trainingDropdownItems} handleExerciseDataChange={handleExerciseDataChange} t={t}/> : null}
                                </div>

                            </div>
                        ) : null}

                        <div className="webapp-create-training-change-step-buttons">
                            <button className="create-training-previous-step-button" onClick={previousStep}>{t('Previous Step')}</button>
                            {step === 4 ? <button className="create-training-save-button" onClick={() => saveExercise()}>{t('Save Exercise')}</button> :
                                <button className="create-training-next-step-button" onClick={nextStep}>{t('Next Step')}</button>}
                        </div>
                    </div>
                </div>
            </div>
            {openImportExercisePopup ? <ImportItemsPopup itemsList={allExercisesList} closePopup={setOpenImportExercisePopup} importItem={importExercise} /> : null}
            {openCoachPaint ? <div className="webapp-create-training-paint"><CoachPaint handleReturn={handleOpenCoachPaint} currentExercise={currentExercise} handleExerciseDataChange={handleExerciseDataChange} drawing={drawing} handleDrawingDataChange={handleDrawingDataChange} /></div> : null}
        </div>
    )

}


export default CreateTrainingPage;
